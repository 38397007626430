import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image.js';
import PropTypes from 'prop-types';
import * as Styled from './ImageUploader.styled.js';

const propTypes = {
  className         : PropTypes.string,
  id                : PropTypes.string,
  label             : PropTypes.string,
  onFilesSelected   : PropTypes.func,
  onFilesDeselected : PropTypes.func,
  error             : PropTypes.string,
  invalidFiles      : PropTypes.array
};
const defaultProps = {
  error             : '',
  className         : '',
  id                : '',
  label             : '',
  invalidFiles      : [],
  onFilesSelected   : () => {},
  onFilesDeselected : () => {}
};

const ImageUploader = (props) => {
  const {
    className, id, label, onFilesSelected, onFilesDeselected, error, invalidFiles
  } = props;

  const dropArea = useRef(null);
  const [files, setFiles] = useState([]);
  const [filePaths, setFilePaths] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [invalidFileNames, setInvalidFileNames] = useState([]);
  const [filesSelected, setFilesSelected] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);
  const handleFilesUpload = (event) => {
    if ([...event.target.files].length > 3) {
      window.alert('Please choose only up to 3 images.');
      return;
    }

    if (filePaths.length >= 3) {
      window.alert('You can only choose up to 3 images.');
      return;
    }
    const filteredFiles = [...event.target.files].filter(file => file.type.includes('image') && !file.type.includes('gif'));
    if ([...filteredFiles].length > 0 && filePaths.length < 3) {
      const paths = [...filteredFiles].map(file => URL.createObjectURL(file));
      setFilePaths([
        ...filePaths,
        ...paths
      ]);

      const names = [...filteredFiles].map(file => file.name);
      setFileNames([
        ...fileNames,
        ...names
      ]);

      setFilesSelected(true);
      setFiles([
        ...files,
        ...event.target.files
      ]);
    } else if (filePaths.length === 0) {
      setFilesSelected(false);
    }
  };

  const removeImage = (imageIndex) => {
    const filePathsCopy = [...filePaths];
    filePathsCopy.splice(imageIndex, 1);
    setFilePaths([...filePathsCopy]);

    const fileNamesCopy = [...fileNames];
    fileNamesCopy.splice(imageIndex, 1);
    setFileNames([...fileNamesCopy]);

    const filesCopy = [...files];
    filesCopy.splice(imageIndex, 1);
    setFiles([...filesCopy]);
    setImageRemoved(true);
  };

  useEffect(() => {
    if (filePaths.length > 0 && fileNames.length > 0 && Array.from(files).length > 0) {
      onFilesSelected({
        filePaths,
        fileNames,
        files
      });
    }

    const invalidArray = [];
    invalidFiles && invalidFiles.forEach((file) => {
      fileNames.includes(file.name) && invalidArray.push(file.name);
    });
    setInvalidFileNames(invalidArray);
    imageRemoved &&
    onFilesDeselected({
      filePaths,
      fileNames,
      files
    });
    setImageRemoved(false);

  }, [filePaths, fileNames, files, onFilesSelected, invalidFiles]);

  return (
    <>
      <Styled.Wrapper className={className}>
        <Styled.InnerWrapper ref={dropArea} $filesSelected={filesSelected}>
          <Styled.Input
            type="file"
            id={id}
            multiple
            accept="image/*"
            onChange={handleFilesUpload}
          />
          {filesSelected && (
            <Styled.ImagePreviews>
              {
                filePaths.map((filePath, index) => (
                  <Styled.ImagePreview
                    key={`ImagePreview-${index}`}
                    error={invalidFileNames.includes(fileNames[index])}
                  >
                    <Image
                      src={filePath}
                      key={`File-${index}`}
                      alt={fileNames[index]}
                      layout="fill"
                      objectFit="cover"
                    />
                    <Styled.RemoveImageButton
                      onClick={() => removeImage(index)}
                      type="button"
                      size="small"
                      theme="white"
                    />
                  </Styled.ImagePreview>
                ))
              }
            </Styled.ImagePreviews>
          )}
          <Styled.Description
            dangerouslySetInnerHTML={{ __html : label }}
            htmlFor={id}
          />
        </Styled.InnerWrapper>
      </Styled.Wrapper>
      <Styled.ErrorMessage>{error}</Styled.ErrorMessage>
    </>
  );
};

ImageUploader.propTypes = propTypes;
ImageUploader.defaultProps = defaultProps;

export default ImageUploader;
