import styled from 'styled-components';
import { motion } from 'framer-motion';
import { genericFormStyles, setTypography, flexCenter } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.div`
  ${genericFormStyles()}
  height: 100%;
  justify-content: flex-start;
  padding: 140px 0 120px;
  ${mediaDesktop(`
    justify-content: center;
  `)}
`;

export const Heading = styled.h3`
  ${setTypography('h3')}
  position: absolute;
  top: 60px;
  left: 0%;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  ${mediaDesktop(`
    position: relative;
    top: unset;
    left: unset;
  `)}
`;
export const DescriptionWrapper = styled.p`
 ${flexCenter()}
 overflow-x: hidden;
 `;
export const Description = styled.p`
  ${setTypography('paragraphWithoutIndent')}
  color: ${colors.grey1};
  text-align: center;
  // width: 75%;
  width: 100%;
  padding: 0 12.5%;
  margin-bottom: 1em;
  ${mediaDesktop(`
    overflow-x: visible;
    width: 65%;
    padding: 0;
  `)}
  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.grey1};
  }
`;

export const ButtonWrapper = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  ${mediaDesktop(`
    position: relative;
    bottom: unset;
    left: unset;
  `)}
`;
