import { useEffect } from 'react';
import { get } from 'lodash';
import { _axios } from '@/utils/axios-helper';
import { ENDPOINT_THEMES_GET } from '@/config/config.endpoints.js';
import { useAppStore } from '@/store';

const useThemesFetcher = () => {
  const setThemes = useAppStore(state => state.setThemes);

  useEffect(() => {
    const fetchThemes = async () => {
      const response = await _axios({
        url    : ENDPOINT_THEMES_GET,
        method : 'GET'
      });
      const data = get(response, 'data.data', []);
      setThemes([
        ...data.map(theme => ({
          id    : get(theme, 'id', ''),
          label : get(theme, 'attributes.name', '')
        }))
      ]);
    };

    fetchThemes();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useThemesFetcher;
