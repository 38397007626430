/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from 'zustand';
import shallow from 'zustand/shallow';

export const performanceStore = create((set, get) => ({

  performanceTier    : 2,
  setPerformanceTier : (tier) => set(() => ({ performanceTier : Math.max(tier, 1) })),
  stepDownTier       : () => set(() => ({ performanceTier : Math.max(get().performanceTier - 1, 1) }))

}));

/**
  * shallow by default to prevent
  * unecessary re-rendering.
  */
const usePerformanceStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return performanceStore(cb, shallow);
    } else {
      return performanceStore(cb);
    }
  } else {
    return performanceStore();
  }
};

export default usePerformanceStore;
