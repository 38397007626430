import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router.js';
import React, { useEffect } from 'react';
import Link from 'next/link';
import device from '@/utils/device.js';
import { copy } from '@/locales/en.js';
import { colors } from '@/styles/_theme.js';
import FooterSocials from '@/components/ui/FooterSocials/FooterSocials.jsx';
import { useAppStore } from '@/store';
import * as Styled from './MenuFooter.styled.js';

const propTypes = {};
const defaultProps = {};
const variants = {
  initial : {  opacity : 0 },
  animate : {
    opacity : 1, transition : { delay : 1, duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const MenuFooter = (props) => {
  const { setMenuOpened } = useAppStore(state => ({
    setMenuOpened : state.setMenuOpened
  }));
  const router = useRouter();
  useEffect(() => {
    const end = () => {
      setMenuOpened(false);
    };
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events, setMenuOpened]);

  return (
    <Styled.Wrapper
      variants={variants}
    >
      <Styled.FooterLeft>{copy.MENU_COPYRIGHT}</Styled.FooterLeft>
      <Styled.FooterCenter>
        <Styled.LinkStyled
          whileHover={{
            color      : colors.silver,
            transition : { duration : 0.2 }
          }}
        >
          <Link
            href="/privacy-policy"
          >
            {copy.MENU_PRIVACY_POLICY}
          </Link>
        </Styled.LinkStyled>
        <Styled.LinkStyled

          whileHover={{
            color      : colors.silver,
            transition : { duration : 0.2 }
          }}
        >
          <Link
            href="/terms"
          >
            {copy.MENU_TERMS}
          </Link>
        </Styled.LinkStyled>
      </Styled.FooterCenter>
      <Styled.FooterRight><FooterSocials hasLabel={device.detection.isDesktop} /></Styled.FooterRight>
    </Styled.Wrapper>
  );
};

MenuFooter.propTypes = propTypes;
MenuFooter.defaultProps = defaultProps;

export default MenuFooter;
