import React from 'react';
import { useNonFunctionalsStore } from '@/store';
import * as Styled from './NonFunctionals.styled.js';

const propTypes = {};
const defaultProps = {};

const NonFunctionals = () => {
  const {
    windowTooSmall,
    webGLDisabled,
    deviceNotSupported
  } = useNonFunctionalsStore(state => ({
    windowTooSmall     : state.windowTooSmall,
    webGLDisabled      : state.webGLDisabled,
    deviceNotSupported : state.deviceNotSupported
  }));

  const isNonFunctional = windowTooSmall || webGLDisabled || deviceNotSupported;

  return (
    isNonFunctional
      ? (
        <Styled.Wrapper>
          <Styled.Label>Window Too Small : { windowTooSmall ? 'true' : 'false' }</Styled.Label>
          <Styled.Label>WebGL Disabled : { webGLDisabled ? 'true' : 'false' }</Styled.Label>
          <Styled.Label>Device Not Supported : { deviceNotSupported ? 'true' : 'false' }</Styled.Label>
        </Styled.Wrapper>
      )
      : null
  );
};

NonFunctionals.propTypes = propTypes;
NonFunctionals.defaultProps = defaultProps;

export default NonFunctionals;
