import styled from 'styled-components';
import { colors } from '@/styles/_theme';
import { setTypography } from '@/styles/_mixins';

export const Wrapper = styled.div`
  width:100%;
`;

export const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  height: 280rem;
  caret-color: ${colors.gold};
  padding: 24rem 24rem;
  border: 1px solid ${colors.grey3};
  resize: none;
  outline: none;
  background: transparent;
  ${setTypography('paragraphWithoutIndent')}
`;

export const Description = styled.p`
  ${setTypography('detail')};
  color: ${props => props.$hasError ? colors.red : colors.grey2};
  margin-top: 10rem;
`;
