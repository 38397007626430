import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '@/styles/_theme';
import { setTypography } from '@/styles/_mixins';

export const Wrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  ${setTypography('h2')};
  a {
    text-decoration: none;
    color: ${colors.silver};
    text-transform: uppercase;
  };
`;
