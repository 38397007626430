import styled from 'styled-components';
import { setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const Divider = styled.span`
  ${setTypography('theme')}
  color: ${colors.grey1};
`;

export const Label = styled.span`
  ${setTypography('theme')}
  color: ${colors.grey1};
`;
