import styled from 'styled-components';
import { motion } from 'framer-motion';
import { genericFormStyles, setTypography } from '@/styles/_mixins';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.div`
  ${genericFormStyles()}
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding: 20rem 0 100rem;
  margin: 40px 0 0;

  ${mediaDesktop(`
    justify-content: center;
  `)}
`;

export const Heading = styled.h3`
  ${setTypography('h3')}
  margin-bottom: 24rem;
  position: absolute;
  top: 70px;
  left: 0%;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  ${mediaDesktop(`
    position: relative;
    top: unset;
    left: unset;
  `)}
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30rem;
`;
export const FormWrapper = styled.div`
  // width: 75%;
  padding: 0 12.5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  row-gap: 30rem;
  padding-bottom: 50rem;
  ${mediaDesktop(`
    overflow-x: unset;
    // width: 65%;
  `)}
`;

export const ThemePickerWrapper = styled.div`
  margin-top: 20rem;
  margin-bottom: 20rem;
`;
export const ButtonWrapper = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  ${mediaDesktop(`
    position: relative;
    bottom: unset;
    left: unset;
  `)}
`;
