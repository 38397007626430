import React, {
  useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { useRouter } from 'next/router.js';
import gsap from 'gsap';
import { OneMinusDstAlphaFactor } from 'three';
import Dash from '@/assets/svgs/dash.svg';
import { useAppStore } from '@/store';
import MenuItem from '@/components/ui/MenuItem/MenuItem.jsx';
import * as Styled from './Menu.styled.js';

const propTypes = {
  list : PropTypes.arrayOf(
    PropTypes.shape({
      title : PropTypes.string,
      path  : PropTypes.string
    })
  )
};

const defaultProps = {
  list : []
};

const MENU_ITEMS = [
  {
    title : 'Amphitheatre',
    path  : '/amphitheatre'
  },
  {
    title : 'sculptures',
    path  : '/sculptures'
  },
  {
    title : 'stories',
    path  : '/stories'
  },
  {
    title : 'exhibition',
    path  : '/exhibition'
  },
  {
    title : 'About us',
    path  : '/about-us'
  },
  {
    title : 'E.B. Cox',
    path  : '/eb-cox'
  }
];

const variants = {
  initial : { scaleX : '150%', opacity : 0 },
  animate : { scaleX : '100%',  opacity : 1, transition : { delay : 0.7, duration : 1, ease : 'easeInOut' } },
  exit    : { scaleX : '150%', opacity : 0 }
};
const variants2 = {
  initial : { scaleX : '150%', x : '-100%', opacity : 0 },
  animate : {
    scaleX : '100%', x : '-100%', opacity : 1, transition : { delay : 0.7, duration : 1, ease : 'easeInOut' }
  },
  exit : { scaleX : '150%', opacity : 0 }
};
const Menu = (props) => {
  const { setMenuOpened } = useAppStore(state => ({
    setMenuOpened : state.setMenuOpened
  }));

  const router = useRouter();
  const [listHovered, setListHovered] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(-1);
  const leftDash = useRef();
  const rightDash = useRef();

  useEffect(() => {
    const end = () => {
      setMenuOpened(false);
    };
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);
    return () => {
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router.events, setMenuOpened]);

  const onHover = (index) => {
    const el = document.querySelector(`#menuItem-${index}`);
    if (el) {
      gsap.to(leftDash.current, {
        y         : el.offsetTop + el.clientHeight / 2,
        opacity   : index === -1 ? 0 : 1,
        overwrite : true
      });
      gsap.to(rightDash.current, {
        y         : el.offsetTop + el.clientHeight / 2,
        opacity   : index === -1 ? 0 : 1,
        overwrite : true

      });
    }
  };

  useEffect(() => {
    const i = MENU_ITEMS.findIndex((menu) => {

      const isStoriesSubRoute = router.route.includes('/stories/');
      const isSculpturesSubRoute = router.route.includes('/sculptures/');
      const onAmphitheatrePage = router.route.includes('/amphitheatre');
      let isCurrent = false;
      if (isStoriesSubRoute) {
        isCurrent = menu.path === '/stories';
      } else if (isSculpturesSubRoute) {
        isCurrent = menu.path === '/sculptures';
      } else if (onAmphitheatrePage) {
        isCurrent = menu.path === '/amphitheatre';
      } else {
        isCurrent = router.route === menu.path;
      }
      return isCurrent;
    });
    setDefaultIndex(i >= 0 ? i : -1);
    onHover(i >= 0 ? i : -1);
    const onLegalPage = router.route.includes('/privacy') || router.route.includes('/terms');

    if (listHovered && onLegalPage) {
      // gsap.to([leftDash.current, rightDash.current], {
      //   opacity  : 1,
      //   duration : 0.2
      // });
    } else if (!listHovered && onLegalPage) {
      gsap.to([leftDash.current, rightDash.current], {
        opacity   : 0,
        duration  : 0.2,
        overwrite : true

      });
    }
  }, [router.route, listHovered]);
  return (
    <Styled.Wrapper
      onMouseEnter={() => setListHovered(true)}
      onMouseLeave={() => setListHovered(false)}
      onMouseOut={() => onHover(defaultIndex)}

    >

      <Styled.Dash ref={leftDash}>
        <motion.div layoutId="dashLeft" variants={variants}>
          <Dash />
        </motion.div>
      </Styled.Dash>

      <AnimateSharedLayout >
        {MENU_ITEMS.map((menu, index) => {

          return (
            <Styled.Item
              key={menu.path}
              animate={{ opacity : listHovered ? 0.5 : 1 }}
              whileHover={{ opacity : 1 }}
              transition={{ duration : 0.2 }}
              onMouseOver={() => onHover(index)}
              id={`menuItem-${index}`}
            >
              <MenuItem
                title={menu.title}
                path={menu.path}
              />
            </Styled.Item>
          );
        })}
      </AnimateSharedLayout>
      <Styled.Dash ref={rightDash}>
        <motion.div
          layoutId="dashRight"
          style={{ rotate : 180 }}
          variants={variants}
        >
          <Dash />
        </motion.div>
      </Styled.Dash>
    </Styled.Wrapper>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
