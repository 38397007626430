import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  // transition: all 0.2s;
  // &:hover {
  //   transform: scale(1.1);
  // }
`;
