import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import SubmissionFormStepOne from '@/components/ui/SubmissionFormStepOne/SubmissionFormStepOne.jsx';
import SubmissionFormStepTwo from '@/components/ui/SubmissionFormStepTwo/SubmissionFormStepTwo.jsx';
import SubmissionFormStepThree from '@/components/ui/SubmissionFormStepThree/SubmissionFormStepThree.jsx';
import SubmissionFormStepFour from '@/components/ui/SubmissionFormStepFour/SubmissionFormStepFour.jsx';
import SubmissionFormStepFive from '@/components/ui/SubmissionFormStepFive/SubmissionFormStepFive.jsx';
import SubmissionFormPagination from '@/components/ui/SubmissionFormPagination/SubmissionFormPagination.jsx';
import { useStorySubmissionStore } from '@/store';
import * as Styled from './SubmissionForm.styled.js';

const propTypes = {};
const defaultProps = {};

const SubmissionForm = (props) => {
  const [step, setStep] = useState(1);
  const [delayedStep, setDelayedStep] = useState(1);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedStep(step);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [step]);
  const { setStorySubmissionFormOpened, setStoryPrivacyPolicyAgreed } = useStorySubmissionStore(state => ({
    setStorySubmissionFormOpened : state.setStorySubmissionFormOpened,
    setStoryPrivacyPolicyAgreed  : state.setStoryPrivacyPolicyAgreed
  }));

  const handleClose = () => {
    setStorySubmissionFormOpened(false);
    setStoryPrivacyPolicyAgreed(false);
  };
  const paginationVariants = {
    initial : { y : '10%', opacity : 0 },
    animate : {
      y : '0%',  opacity : 1, transition : { duration : 0.8, ease : 'easeOut' }
    },
    exit : { opacity : 0 }
  };
  return (
    <Styled.Wrapper $isFirstOrLastStep={delayedStep === 1 || delayedStep === 5}>
      <Styled.CloseButton
        icon="close"
        onClick={handleClose}
      />
      <Styled.Border />
      <AnimatePresence exitBeforeEnter>
        {
          delayedStep >= 2 && step <= 4 && (
            <Styled.Pagination
              as={motion.div}
              variants={paginationVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <SubmissionFormPagination
                currentPage={delayedStep - 1}
                maxPages={3}
              />
            </Styled.Pagination>
          )
        }
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {step === 1 && <SubmissionFormStepOne key="submission-form-step-1" onSubmit={() => setStep(2)} />}
        {step === 2 && <SubmissionFormStepTwo key="submission-form-step-2" onSubmit={() => setStep(3)} />}
        {step === 3 && <SubmissionFormStepThree key="submission-form-step-3" onSubmit={() => setStep(4)} />}
        {step === 4 && <SubmissionFormStepFour key="submission-form-step-4" onSubmit={() => setStep(5)} />}
        {step === 5 && <SubmissionFormStepFive key="submission-form-step-5" />}
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

SubmissionForm.propTypes = propTypes;
SubmissionForm.defaultProps = defaultProps;

export default SubmissionForm;
