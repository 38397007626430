import Joi from 'joi';

export const validateEmail = (email = '') => {
  const schema = Joi.string().email({ tlds : { allow : false } });
  return schema.validate(email);
};

export const validateName = (name = '') => {
  const schema = Joi.string().required()
    .max(50);
  return schema.validate(name);
};

export const validateStoryTitle = (title = '') => {
  const schema = Joi.string().required()
    .max(100);
  return schema.validate(title);
};

export const validateStoryContent = (story = '') => {
  const wordCount = story.length ? story.trim().split(/\s+/).length : 0;
  const validation = (wordCount >= 1 && wordCount <= 3000 ) ?
    { value : story }
    : {
      value : story,
      error : true
    };
  return validation;
};

export const validateFiles = (files = []) => {
  const inValidFiles = files ? files.filter((file) => file.size > 10485760) : [];
  return { files : inValidFiles, error : inValidFiles.length > 0 ? true : false };
};
