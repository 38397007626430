
/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from 'zustand';
import shallow from 'zustand/shallow';

export const urlQueriesStore = create((set) => ({
  debug    : false,
  setDebug : (val) => set(() => ({
    debug : val
  }))
}));

/**
   * shallow by default to prevent
   * unecessary re-rendering.
   */
const useUrlQueriesStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return urlQueriesStore(cb, shallow);
    } else {
      return urlQueriesStore(cb);
    }
  } else {
    return urlQueriesStore();
  }
};

export default useUrlQueriesStore;
