import styled from 'styled-components';
import IconButton from '../IconButton/IconButton';

export const IconWrapper = styled(IconButton)`
  top: 0%;
  left: 0;
  // &:hover {
  //   transform:scale(1.1);
  // }
  // transition: all 0.2s;

`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;
