import { mountStoreDevtool } from 'simple-zustand-devtools';
import useSculpturesSliderStore, { sculpturesSliderStore } from '@/store/_sculptures-slider';
import usePerformanceStore, { performanceStore } from '@/store/_performance';
import useWindowSizeStore, { windowSizeStore } from './_window-size';
import useUrlQueriesStore, { urlQueriesStore } from './_url-queries';
import useNonFunctionalsStore, { nonFunctionalsStore } from './_non-functionals';
import useStorySubmissionStore, { storySubmissionStore } from './_story-submission';
import useAppStore, { appStore } from './_app';
import useCanvasStore, { canvasStore } from './_canvas';

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  mountStoreDevtool('window size', windowSizeStore);
  mountStoreDevtool('non functionals', nonFunctionalsStore);
  mountStoreDevtool('url queries', urlQueriesStore);
  mountStoreDevtool('story submission', storySubmissionStore);
  mountStoreDevtool('slider store', sculpturesSliderStore);
  mountStoreDevtool('app', appStore);
  mountStoreDevtool('canvas', canvasStore);
  mountStoreDevtool('performance', performanceStore);
}

export {
  useWindowSizeStore,
  useNonFunctionalsStore,
  useUrlQueriesStore,
  useStorySubmissionStore,
  useSculpturesSliderStore,
  useAppStore,
  useCanvasStore,
  usePerformanceStore
};
