
/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from 'zustand';
import shallow from 'zustand/shallow';

export const nonFunctionalsStore = create((set) => ({
  windowTooSmall        : false,
  setWindowTooSmall     : (val) => set(() => ({ windowTooSmall : val })),
  webGLDisabled         : false,
  setWebGLDisabled      : (val) => set(() => ({ webGLDisabled : val })),
  deviceNotSupported    : false,
  setDeviceNotSupported : (val) => set(() => ({ deviceNotSupported : val }))
}));

/**
    * shallow by default to prevent
    * unecessary re-rendering.
    */
const useNonFunctionalsStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return nonFunctionalsStore(cb, shallow);
    } else {
      return nonFunctionalsStore(cb);
    }
  } else {
    return nonFunctionalsStore();
  }
};

export default useNonFunctionalsStore;
