import styled from 'styled-components';
import IconButton from '@/components/ui/IconButton/IconButton';
import PaddedBorders from '@/components/ui/PaddedBorders/PaddedBorders.jsx';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background-color: ${colors.silver};
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.$isFirstOrLastStep ? 'safe center' : 'flex-start'};
  align-items: center;
  margin: auto;

  ${mediaDesktop(`
    overflow-x: visible;
  `)};

  ${props => props.$isFirstOrLastStep
    ? `padding-top: 0rem;
    // padding-bottom: 120rem;
    ${mediaDesktop(`
      padding: 0;
    `)};
    ` :
    `padding-top: 60rem;
     padding-bottom: 70rem;
    ${mediaDesktop(`
      padding-top: 100rem;
      padding-bottom: 70rem;
    `)};
  `}
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  top: 16rem;
  right: 16rem;

  ${mediaDesktop(`
    top: 40rem;
    right: 40rem;
  `)};
`;

export const Pagination = styled.div`
  margin-bottom: 30rem;
  margin-top: -30rem;

  ${mediaDesktop(`
    margin-top: unset;
  `)};
`;
export const Border = styled(PaddedBorders)`
  visibility: hidden;
  ${mediaDesktop(`
    visibility: visible;
  `)};
`;
