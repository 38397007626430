import styled from 'styled-components';
import { ctaDropShadow, fullSize, setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';
import IconButton from '@/components/ui/IconButton/IconButton';

export const Wrapper = styled.div`
  ${ctaDropShadow()}
  position: relative;
  width: 100%;
  height: 215rem;
  padding: 4rem;
  background-color: ${colors.white};
  ${mediaDesktop(`
    height: 300rem;
  `)}
`;
export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.$filesSelected ? 'flex-start' : 'center'};
  align-items: center;
  width: 100%;
  height: 100%;
  border: ${props => props.$draggedOver ? '3px' : '1px'} solid ${colors.gold};

`;

export const Input = styled.input`
  ${fullSize()}
  opacity: 0;
  cursor: pointer;
`;

export const Description = styled.label`
  ${setTypography('paragraphWithoutIndent')}
  text-align: center;
  user-select: none;
  color: ${colors.grey1};
`;

export const ImagePreviews = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
  padding: 20rem 27rem 14rem 27rem;
  column-gap: 20rem;
  row-gap: 20rem;
  ${mediaDesktop(`
    padding: 20rem;
  `)}
`;

export const ImagePreview = styled.li`
  position: relative;
  height: 120rem;
  img {
    filter: grayscale(1);
  }
  outline: ${props => {
    return props.error === true && '1px solid red';
  }};
  outline-offset: 2px;
  ${mediaDesktop(`
    height: 160rem;
  `)}
`;

export const RemoveImageButton = styled(IconButton)`
  position: absolute;
  top: 6rem;
  right: 6rem;
`;

export const ErrorMessage = styled.div`
  ${setTypography('detail')};
  color: ${colors.red};
  margin-top: -20rem;
`;
