import { useEffect, useState } from 'react';
import { useWindowSizeStore, useStorySubmissionStore } from '@/store';
import {
  TABLET_MIN_WIDTH,
  TABLET_MAX_WIDTH,
  TABLET_MIN_HEIGHT,
  TABLET_MAX_HEIGHT
} from '@/config/config.settings';
import { copy } from '@/locales/en.js';
import device from '@/utils/device.js';

const useIsDeviceSupported = () => {
  const [isDeviceSupported, setIsDeviceSupported] = useState(true);
  const [displayText, setDisplayText] = useState({
    heading    : '',
    subheading : ''
  });
  const [showBorderedLayout, setShowBorderedLayout] = useState(false);

  const windowSize = useWindowSizeStore(state => ({
    width  : state.width,
    height : state.height
  }));
  const { storySubmissionFormOpened } = useStorySubmissionStore(state => ({
    storySubmissionFormOpened : state.storySubmissionFormOpened
  }));

  const {
    isDesktop, isMobile, isTablet, isAndroid
  } = device.detection;

  const resetDefaults = () => {
    setIsDeviceSupported(true);
    setDisplayText({
      heading    : '',
      subheading : ''
    });
    setShowBorderedLayout(false);
  };

  useEffect(() => {
    if (isAndroid && !storySubmissionFormOpened) {
      document.documentElement.style.height = `${0}px`;
      document.body.style.height = `${0}px`;
      document.documentElement.style.width = `${0}px`;
      document.body.style.width = `${0}px`;

      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      document.documentElement.style.height = `${h}px`;
      document.body.style.height = `${h}px`;
      document.documentElement.style.width = `${w}px`;
      document.body.style.width = `${w}px`;
    }
  }, [isAndroid, storySubmissionFormOpened, windowSize]);

  useEffect(() => {
    if (!!windowSize) {
      if (isDesktop) {
        const { width, height } = windowSize;

        if (
          (width >= TABLET_MAX_WIDTH && height >= TABLET_MAX_HEIGHT) ||
                  (width <= TABLET_MIN_WIDTH && height >= TABLET_MIN_HEIGHT)
        ) {
          resetDefaults();
        } else {
          setDisplayText({
            heading    : copy.DESKTOP_OVERLAY_TEXT,
            subheading : ''
          });
          setIsDeviceSupported(false);
          setShowBorderedLayout(false);
        }
      }
      if (isMobile) {
        if (isTablet) {
          setDisplayText({
            heading    : copy.QR_HEADING_TABLET,
            subheading : copy.QR_SUBHEADING_TABLET
          });
          setIsDeviceSupported(false);
          setShowBorderedLayout(true);

        } else  {
          if (window.innerHeight < window.innerWidth) {
            setDisplayText({
              heading : copy.MOBILE_LANDSCAPE_OVERLAY_TEXT
            });
            setIsDeviceSupported(false);
            setShowBorderedLayout(false);
          } else {
            // alert('landscape');
            resetDefaults();
          }

          if (isAndroid && storySubmissionFormOpened) {
            resetDefaults();
          }
        }
      }

    }

  }, [windowSize, isDesktop, isMobile, isTablet, isAndroid, storySubmissionFormOpened]);

  return { isDeviceSupported, displayText, showBorderedLayout };
};

export default useIsDeviceSupported;
