import React, {
  useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import Draggable  from 'gsap/dist/Draggable';
import InertiaPlugin from 'gsap/dist/InertiaPlugin';
import gsap from 'gsap';
import Chip from '@/components/ui/Chip/Chip.jsx';
import { useAppStore, useStorySubmissionStore, useWindowSizeStore } from '@/store';
import { copy } from '@/locales/en.js';
import device from '@/utils/device.js';
import * as Styled from './ThemePicker.styled.js';

const propTypes = {
  themes        : PropTypes.array,
  where         : PropTypes.oneOf(['storiesPage', 'submissionPage']),
  error         : PropTypes.string,
  onThemePicked : PropTypes.func
};

const defaultProps = {
  themes        : [],
  where         : 'storiesPage',
  error         : '',
  onThemePicked : () => {}
};
const variants = {
  initial : { y : '20%', letterSpacing : '33.2733px', opacity : 0 },
  animate : {
    y : '0%', letterSpacing : '13.2733px',  opacity : 1, transition : {  duration : 1, ease : 'easeInOut' }
  },
  exit : { opacity : 0 }
};

const variantsFade = {
  initial : { y : '20%', opacity : 0 },
  animate : {
    y : '0%',  opacity : 1, transition : { delay : 0.5, duration : 1, ease : 'easeInOut' }
  },
  exit : { opacity : 0 }
};
const ThemePicker = (props) => {

  const scrollDiv = useRef();
  const scrollBound = useRef();

  const {
    themes, where, error, onThemePicked
  } = props;

  const {
    storiesChosenThemes,
    setStoriesChosenThemes
  } = useAppStore(state => ({
    storiesChosenThemes    : state.storiesChosenThemes,
    setStoriesChosenThemes : state.setStoriesChosenThemes
  }));

  const windowSize = useWindowSizeStore(state => ({
    width  : state.width,
    height : state.height
  }));

  useEffect(() => {
    let drag;
    if (device.detection.isMobile && windowSize.width < 712) {
      gsap.registerPlugin(Draggable);
      gsap.registerPlugin(InertiaPlugin);
      if (scrollDiv && scrollBound) {
        Draggable.create(scrollDiv.current, {
          type           : 'x',
          edgeResistance : 0.65,
          inertia        : true,
          bounds         : scrollBound.current,
          lockAxis       : true
        });
      };
    }
    return () => {
      drag && drag[0].kill();
    };
  }, [scrollDiv, windowSize]);

  const {
    submissionChosenThemes,
    setSubmissionChosenThemes
  } = useStorySubmissionStore(state => ({
    submissionChosenThemes    : state.submissionChosenThemes,
    setSubmissionChosenThemes : state.setSubmissionChosenThemes
  }));

  const handleChipPick = (themeId = null, chosen = false) => {
    if (where === 'storiesPage') {
      if (themeId && !storiesChosenThemes.includes(themeId) && chosen) {
        // setStoriesChosenThemes([...storiesChosenThemes, themeId]);
        setStoriesChosenThemes([themeId]);
      } else if (themeId && storiesChosenThemes.includes(themeId) && !chosen) {
        // const indexOfUnpickedTheme = storiesChosenThemes.indexOf(themeId);
        // const chosenThemesCopy = [...storiesChosenThemes];
        // chosenThemesCopy.splice(indexOfUnpickedTheme, 1);
        // setStoriesChosenThemes([...chosenThemesCopy]);
        setStoriesChosenThemes([]);
      }
    } else {
      if (themeId && !submissionChosenThemes.includes(themeId) && chosen) {
        // setSubmissionChosenThemes([...submissionChosenThemes, themeId]);
        setSubmissionChosenThemes([themeId]);
      } else if (themeId && submissionChosenThemes.includes(themeId) && !chosen) {
        // const indexOfUnpickedTheme = submissionChosenThemes.indexOf(themeId);
        // const chosenThemesCopy = [...submissionChosenThemes];
        // chosenThemesCopy.splice(indexOfUnpickedTheme, 1);
        // setSubmissionChosenThemes([...chosenThemesCopy]);
        setSubmissionChosenThemes([]);
      }
    }
  };
  const handleChipClick = (themeId, chosen) => {
    handleChipPick(themeId, chosen);
    onThemePicked();
  };

  return (
    <Styled.Wrapper >
      <Styled.Label variants={variants}>
        {copy.THEME_PICKER_LABEL}
      </Styled.Label>
      <Styled.ChipsWrapper ref={scrollBound} variants={variantsFade}>
        <Styled.Chips
          ref={scrollDiv}
        >
          {
            themes.map(theme => {
              const picked = where === 'storiesPage' ?
                storiesChosenThemes.includes(theme.id) :
                submissionChosenThemes.includes(theme.id);
              return (
                <Chip
                  key={theme.id}
                  label={theme.label}
                  picked={picked}
                  onClick={(chosen) => handleChipClick(theme.id, chosen)}
                />
              );
            })
          }
        </Styled.Chips>
      </Styled.ChipsWrapper>
      {error && (
        <Styled.Error>
          {error}
        </Styled.Error>
      )}
    </Styled.Wrapper>
  );
};

ThemePicker.propTypes = propTypes;
ThemePicker.defaultProps = defaultProps;

export default ThemePicker;
