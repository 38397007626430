import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import _ from 'lodash';
import { capitalCase } from 'change-case';
import { useRouter } from 'next/router.js';
import PaddedBorders from '@/components/ui/PaddedBorders/PaddedBorders.jsx';
import { copy } from '@/locales/en.js';
import { colors } from '@/styles/_theme.js';
import { useAppStore, useSculpturesSliderStore } from '@/store';
import * as Styled from './DesktopQrCode.styled.js';

const propTypes = {};
const defaultProps = {};

const DesktopQrCode = (props) => {
  const router = useRouter();
  const {
    setQrModalOpened,
    qrModalLink
  } = useAppStore(state => ({
    setQrModalOpened : state.setQrModalOpened,
    qrModalLink      : state.qrModalLink
  }));
  const {
    sculpturesData,
    currentSlide
  } = useSculpturesSliderStore((state) => ({
    sculpturesData : state.sculpturesData,
    currentSlide   : state.currentSlide
  }));
  const name = useMemo(() => capitalCase(sculpturesData[currentSlide]?.name ?? ''), [currentSlide, sculpturesData]);
  const subheading = router.pathname !== '/' ? `${copy.QR_SUBHEADING1} ${name} ${copy.QR_SUBHEADING2}` : ``;
  return (
    <Styled.Wrapper>
      <Styled.Background onClick={() => setQrModalOpened(false)} />
      <Styled.Container>
        <PaddedBorders />
        <Styled.Heading>{copy.QR_HEADING}</Styled.Heading>
        <Styled.Subheading>{subheading}</Styled.Subheading>
        <QRCodeSVG
          value={qrModalLink}
          size={80}
          bgColor="transparent"
          fgColor={colors.grey1}
        />
        <Styled.Close icon="close" onClick={() => setQrModalOpened(false)} />
      </Styled.Container>
    </Styled.Wrapper>
  );
};

DesktopQrCode.propTypes = propTypes;
DesktopQrCode.defaultProps = defaultProps;

export default DesktopQrCode;
