import styled from 'styled-components';
import hexRgb from 'hex-rgb';
import { fullSize, flexCenter, setTypography } from '@/styles/_mixins';
import IconButton from '@/components/ui/IconButton/IconButton';
import { colors, layout } from '@/styles/_theme';

const c = hexRgb(colors.black);

export const Wrapper = styled.div`
    position: relative;
    ${fullSize()};
    ${flexCenter()};
    display: flex;
    z-index: ${layout.zIndex.desktopQrCode || 99999};
`;

export const Background = styled.div`
    ${fullSize()}
    background-color: ${props => props.transparentBlur ? `rgba(${c.red}, ${c.green}, ${c.blue}, 0.8)` : colors.silver} ;
    backdrop-filter: blur(5px);
`;

export const Container = styled.div`
    position: relative;
    width: ${props => props.fixedSize ? '593rem' : '100%'};
    height: ${props => props.fixedSize ? '424rem' : '100%'};
    background: ${colors.silver};
    ${flexCenter()};
`;

export const Heading = styled.h3`
    ${setTypography('h3')};
    margin: 0 20rem;
    color: ${colors.gold};
    text-align: center;
`;
export const Subheading = styled.p`
    ${setTypography('paragraphWithoutIndent')};
    margin: 0 20rem;
    margin-top: 16rem;
    margin-bottom: 40rem;
    color: ${colors.grey1};
    text-align: center;
`;
export const Close = styled(IconButton)`
    position: absolute;
    right: 40rem;
    top: 40rem;
`;
