import styled from 'styled-components';
import { colors } from '@/styles/_theme';
import { setTypography, ctaDropShadow } from '@/styles/_mixins';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.button`
  position: relative;
  padding: 4rem;
  border: none;
  border-radius: 28rem;
  background-color: ${colors.white};
  cursor: pointer;
  ${mediaDesktop(`
    ${ctaDropShadow()};
  `)}
`;

export const InnerWrapper = styled.div`
  padding: 7rem 28rem;
  border-radius: 24rem;
  border: 1px solid ${colors.grey2};
`;

export const Label = styled.p`
  ${setTypography('cta')}
  color: ${colors.white};
  text-transform: capitalize;
`;
