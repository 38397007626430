import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './PaddedBorders.styled.js';

const propTypes = {
  className : PropTypes.string
};
const defaultProps = {
  className : ''
};

const PaddedBorders = (props) => {
  const { className } = props;

  return (
    <Styled.Wrapper className={className}>
      <Styled.Borders>
        <Styled.HorizontalBorder $top={0} $left={0}>
          <Styled.Corner />
          <Styled.HorizontalLine />
          <Styled.Corner />
        </Styled.HorizontalBorder>
        <Styled.HorizontalBorder $bottom={0} $left={0}>
          <Styled.Corner />
          <Styled.HorizontalLine />
          <Styled.Corner />
        </Styled.HorizontalBorder>
        <Styled.VerticalBorder $top={0} $left={0}>
          <Styled.Corner />
          <Styled.VerticalLine />
          <Styled.Corner />
        </Styled.VerticalBorder>
        <Styled.VerticalBorder $top={0} $right={0}>
          <Styled.Corner />
          <Styled.VerticalLine />
          <Styled.Corner />
        </Styled.VerticalBorder>
      </Styled.Borders>
    </Styled.Wrapper>
  );
};

PaddedBorders.propTypes = propTypes;
PaddedBorders.defaultProps = defaultProps;

export default PaddedBorders;
