import styled from 'styled-components';
import { fullSize, setTypography } from '@/styles/_mixins';

export const Wrapper = styled.div`
  ${fullSize({
    centered : true
  })}
  background-color: white;
`;

export const Label = styled.p`
  ${setTypography('paragraph')}
  color: black;
`;
