import { useEffect, useMemo } from 'react';
import { useNonFunctionalsStore, useWindowSizeStore } from '@/store';
import { isWebGLAvailable, isWebGL2Available } from '@/utils/webgl-support';
import { checkDeviceSupport } from '@/utils/device-support';
import { MINIMUM_SCREEN_SIZE } from '@/config/config.non-functionals';
import device from '@/utils/device';

const useNonFunctionals = () => {
  const windowSize = useWindowSizeStore(state => ({
    width  : state.width,
    height : state.height
  }));

  const {
    setDeviceNotSupported,
    setWindowTooSmall,
    setWebGLDisabled
  } = useNonFunctionalsStore(state => ({
    setDeviceNotSupported : state.setDeviceNotSupported,
    setWindowTooSmall     : state.setWindowTooSmall,
    setWebGLDisabled      : state.setWebGLDisabled
  }));

  const windowTooSmall = useMemo(() => {
    return (
      windowSize.width < MINIMUM_SCREEN_SIZE.width ||
      windowSize.height < MINIMUM_SCREEN_SIZE.height
    );
  }, [windowSize]);

  // check browser and os support
  useEffect(() => {
    setDeviceNotSupported(!checkDeviceSupport());

    if (!(isWebGLAvailable() && isWebGL2Available())) {
      setWebGLDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check window size
  useEffect(() => {
    if (device.detection.isDesktop) {
      setWindowTooSmall(windowTooSmall);
    }
  }, [windowSize, setWindowTooSmall, windowTooSmall]);
};

export default useNonFunctionals;
