import CircleSvg from '@/assets/svgs/frame.svg';
import { copy } from '@/locales/en.js';
import * as Styled from './Loader.styled.js';

const propTypes = {};
const defaultProps = {};
const Loader = () => {
  return (
    <Styled.Wrapper
      initial={{ opacity : 1 }}
      animate={{ opacity : 1 }}
      exit={{ opacity : 0 }}
      transition={{ duration : 1, delay : 0.25 }}
    >
      <Styled.CircleWrapper>
        <Styled.SVGWrapper
          initial={{ opacity : 0, scale : 1.5 }}
          animate={{ opacity : 1, scale : 1 }}
          exit={{ opacity : 0 }}
          transition={{ duration : 0.8 }}
        >
          <Styled.Spinner
            animate={{ rotate : 360 }}
            transition={{
              duration : 20,
              repeat   : Infinity,
              ease     : 'linear'
            }}
          >
            <CircleSvg id="frame" />
            {/* <Styled.Mask id="frameMask" /> */}
            {/* <Styled.Numbers>
            {percent}
          </Styled.Numbers> */}
          </Styled.Spinner>
        </Styled.SVGWrapper>
      </Styled.CircleWrapper>

      <Styled.LoadingText
        initial={{ opacity : 0, letterSpacing : '.22em' }}
        animate={{ opacity : 1, letterSpacing : '1.2em' }}
        exit={{ opacity : 0 }}
        transition={{ duration : 1, delay : 0.25 }}
      >
        {copy.LOADING}
      </Styled.LoadingText>
    </Styled.Wrapper>
  );
};

Loader.defaultProps = defaultProps;

export default Loader;
