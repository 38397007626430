import styled from 'styled-components';
import { setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10rem;
  span {
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      width: 100%;
      border-bottom: 1px solid ${colors.grey1};
      position: absolute;
      left: 0;
      bottom: 4rem;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10rem;
`;

export const Input = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: none;
`;

export const InputBox = styled.div`
  width: 20rem;
  height: 20rem;
  border: 1px solid ${colors.grey1};
  padding: 3rem;
`;

export const InputBoxInner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.gold};
  opacity: ${props => props.$isChecked ? 1 : 0};
  padding: 3rem;
`;

export const Label = styled.label`
  ${setTypography('detail')}
  margin-top: 3px;
  position: relative;
  user-select: none;
  color: ${colors.black};
`;

export const Error = styled.p`
  ${setTypography('detail')};
  color: ${colors.red};
  margin-top: 10rem;
`;
