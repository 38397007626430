import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap';
import { useRouter } from 'next/router.js';
import MorphSVGPlugin from 'gsap/dist/MorphSVGPlugin';
import Hamburger from '@/assets/svgs/hamburger.svg';
import Close from '@/assets/svgs/close.svg';
import Line from '@/assets/svgs/line.svg';
import Facebook from '@/assets/svgs/facebook.svg';
import Twitter from '@/assets/svgs/twitter.svg';
import Link from '@/assets/svgs/link.svg';
import Chevron from '@/assets/svgs/chevron.svg';
import Like from '@/assets/svgs/like.svg';
import { useAppStore } from '@/store';
import SinWave from '@/components/ui/SinWave/SinWave';
import * as Styled from './IconButton.styled.js';

gsap.registerPlugin(MorphSVGPlugin);

const ICON_SVGS = ['hamburger', 'close', 'curve', 'line', 'facebook', 'twitter', 'link', 'chevron', 'like'];
const ICON_SIZE = ['small', 'normal'];
const ICON_THEME = ['grey', 'white', 'silver'];

const propTypes = {
  icon        : PropTypes.oneOf(ICON_SVGS),
  size        : PropTypes.oneOf(ICON_SIZE),
  theme       : PropTypes.oneOf(ICON_THEME),
  isNotButton : PropTypes.bool,
  rest        : PropTypes.object
};

const defaultProps = {
  icon        : 'close',
  size        : 'normal',
  theme       : 'grey',
  isNotButton : false,
  rest        : {}
};

const IconButton = (props) => {
  const {
    icon, size, theme, isNotButton, ...rest
  } = props;
  const ref = useRef();
  const { menuOpened, soundOn } = useAppStore(state => ({
    menuOpened : state.menuOpened,
    soundOn    : state.soundOn
  }));
  const router = useRouter();
  const isSilver = router.route === '/' || router.route === '/amphitheatre';
  const buttonTheme = theme === 'white' ? 'white' : isSilver ? 'silver' : 'grey';
  const renderedIcon = () => {
    switch (icon) {
      case 'hamburger':
        return <Hamburger />;
      case 'close':
        return <Close />;
      case 'facebook':
        return <Facebook />;
      case 'twitter':
        return <Twitter />;
      case 'curve':
        return <SinWave />;
      case 'line':
        return <Line />;
      case 'link':
        return <Link />;
      case 'chevron':
        return <Chevron />;
      case 'like':
        return <Like />;
      default:
        return;
    }
  };

  useEffect(() => {
    if (icon !== 'hamburger') return;

    if (menuOpened) {
      gsap.to('#animTop', {
        morphSVG : '#closeRight'
      });
      gsap.to('#animMid', {
        opacity : 0
      });
      gsap.to('#animBot', {
        morphSVG : '#closeLeft'
      });
    } else {
      gsap.to('#animTop', {
        morphSVG : '#hamTop'
      });
      gsap.to('#animMid', {
        opacity : 1
      });
      gsap.to('#animBot', {
        morphSVG : '#hamBot'
      });
    }
  }, [menuOpened, icon]);
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Styled.Wrapper
      $size={size}
      $theme={buttonTheme}
      as={isNotButton ? 'span' : 'button'}
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Styled.Icon $size={size} ref={ref}>
        {renderedIcon()}
        {hovered && <Styled.DummyBorder />}
      </Styled.Icon>
    </Styled.Wrapper>
  );
};

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
