import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { copy } from '@/locales/en.js';
import CustomInput from '@/components/ui/CustomInput/CustomInput.jsx';
import Button from '@/components/ui/Button/Button.jsx';
import ThemePicker from '@/components/ui/ThemePicker/ThemePicker';
import { useAppStore, useStorySubmissionStore, useSculpturesSliderStore } from '@/store';
import { validateEmail, validateName } from '@/utils/validator.js';
import * as Styled from './SubmissionFormStepTwo.styled.js';

const propTypes = {
  onSubmit : PropTypes.func
};
const defaultProps = {
  onSubmit : () => {}
};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { opacity : 0, transition : { duration : 0.25 } }
};
const variants = {
  initial : {
    y : '20%', letterSpacing : '0.2em', opacity : 0
  },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const ctaVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 0.99, transition : { delay : 1, duration : 0.8, ease : 'linear' }
  },
  exit : { opacity : 0 }
};
const formVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 1, transition : { duration : 0.8, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const SubmissionFormStepTwo = (props) => {
  const { onSubmit } = props;

  const {
    setStoriesChosenThemes,
    themes
  } = useAppStore(state => ({
    setStoriesChosenThemes : state.setStoriesChosenThemes,
    themes                 : state.themes
  }));
  const {
    currentSlideData
  } = useSculpturesSliderStore(state => ({
    currentSlideData : state.currentSlideData
  }));

  useEffect(() => {
    const theme = currentSlideData.theme;
    const themeId = themes.filter(t => t.label === theme)[0]?.id;
    themeId && setSubmissionChosenThemes([themeId]);
  }, [currentSlideData, setSubmissionChosenThemes, themes]);

  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [nameIsInvalid, setNameIsInvalid] = useState(false);
  const [submissionThemeIsNotPicked, setSubmissionThemeIsNotPicked] = useState(false);

  const {
    name,
    email,
    setName,
    setEmail,
    submissionChosenThemes,
    setSubmissionChosenThemes
  } = useStorySubmissionStore(state => ({
    name                      : state.name,
    email                     : state.email,
    setName                   : state.setName,
    setEmail                  : state.setEmail,
    submissionChosenThemes    : state.submissionChosenThemes,
    setSubmissionChosenThemes : state.setSubmissionChosenThemes
  }));

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate name, email and if a theme is picked
    const nameValidation = validateName(name);
    const emailValidation = validateEmail(email);
    if (nameValidation.error || emailValidation.error || submissionChosenThemes.length < 1) {
      nameValidation.error && setNameIsInvalid(true);
      emailValidation.error && setEmailIsInvalid(true);
      submissionChosenThemes.length < 1 && setSubmissionThemeIsNotPicked(true);
      return;
    }

    onSubmit();
  };

  const handleNameInput = (event) => {
    setName(event.target.value);
    setNameIsInvalid(false);
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
    setEmailIsInvalid(false);
  };

  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Heading
        as={motion.h3}
        variants={variants}
      >
        {copy.SF_STEP2_HEADING}
      </Styled.Heading>
      <Styled.FormWrapper>

        <Styled.Form
          onSubmit={handleSubmit}
          noValidate
          as={motion.form}
          variants={formVariants}
          id="form"
        >
          <CustomInput
            id="submissionFormName"
            label={copy.SF_STEP2_NAME_LABEL}
            description={copy.SF_STEP2_NAME_DESC}
            type="text"
            onInput={handleNameInput}
            value={name}
            error={nameIsInvalid ? copy.SF_STEP2_NAME_ERROR : ''}
            required
          />
          <CustomInput
            id="submissionFormEmail"
            label={copy.SF_STEP2_EMAIL_LABEL}
            description={copy.SF_STEP2_EMAIL_DESC}
            type="email"
            onInput={handleEmailInput}
            value={email}
            error={emailIsInvalid ? copy.SF_STEP2_EMAIL_ERROR : ''}
            required
          />
          <Styled.ThemePickerWrapper>
            <ThemePicker
              themes={themes}
              where="submissionPage"
              onThemePicked={() => setSubmissionThemeIsNotPicked(false)}
              error={submissionThemeIsNotPicked ? copy.SF_STEP2_THEME_ERROR : ''}
            />
          </Styled.ThemePickerWrapper>
        </Styled.Form>
      </Styled.FormWrapper>
      <Styled.ButtonWrapper
        variants={ctaVariants}
      >
        <Button type="submit" form="form">
          {copy.SF_STEP2_CTA}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

SubmissionFormStepTwo.propTypes = propTypes;
SubmissionFormStepTwo.defaultProps = defaultProps;

export default SubmissionFormStepTwo;
