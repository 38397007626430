import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router.js';
import GenericLink from '@/components/ui/GenericLink/GenericLink.jsx';
import * as Styled from './CustomCheckbox.styled.js';

const propTypes = {
  className    : PropTypes.string,
  id           : PropTypes.string,
  label        : PropTypes.string,
  onChecked    : PropTypes.func,
  error        : PropTypes.string,
  defaultValue : PropTypes.bool
};
const defaultProps = {
  className    : '',
  id           : '',
  label        : '',
  onChecked    : () => {},
  error        : '',
  defaultValue : false
};

const CustomCheckbox = (props) => {
  const {
    className, id, label, onChecked, error, defaultValue
  } = props;
  const router = useRouter();
  const labelRef = useRef(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setChecked(defaultValue);
    }
  }, [defaultValue]);

  const handleBoxClick = () => {
    labelRef.current.click();
  };
  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
    onChecked(e.target.checked);
  };
  useEffect(() => {
    const baseUrl = process.env.APP_URL || window?.location.origin;
    labelRef.current.children[0].onclick = (e) => {
      e.preventDefault();
      window.open(`${baseUrl}/privacy-policy`, '_ blank');
    };
    labelRef.current.children[1].onclick = (e) => {
      e.preventDefault();
      window.open(`${baseUrl}/terms`, '_ blank');
    };
  }, [router]);
  return (
    <Styled.Wrapper className={className}>
      <Styled.InputWrapper>
        <Styled.Input
          id={id}
          type="checkbox"
          onChange={handleCheckbox}
          checked={checked}
          required
        />
        <Styled.InputBox onClick={handleBoxClick}>
          <Styled.InputBoxInner $isChecked={checked} />
        </Styled.InputBox>
        <Styled.Label
          htmlFor={id}
          ref={labelRef}
          dangerouslySetInnerHTML={{ __html : label }}
        />
      </Styled.InputWrapper>
      {
        error && (
          <Styled.Error>
            {error}
          </Styled.Error>
        )
      }
    </Styled.Wrapper>
  );
};
CustomCheckbox.propTypes = propTypes;
CustomCheckbox.defaultProps = defaultProps;

export default CustomCheckbox;
