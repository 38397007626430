import { useEffect } from 'react';
import { get } from 'lodash';
import { _axios } from '@/utils/axios-helper';
import { ENDPOINT_SCULPTURES_GET } from '@/config/config.endpoints.js';
import { useSculpturesSliderStore } from '@/store';

const useSculpturesFetcher = () => {
  const {
    setSculpturesData
  } = useSculpturesSliderStore((state) => ({
    setSculpturesData : state.setSculpturesData
  }));

  useEffect(() => {
    const fetchSculptures = async () => {
      const response = await _axios({
        url    : ENDPOINT_SCULPTURES_GET,
        method : 'GET'
      });
      const data = get(response, 'data.data', []);
      const sculptures = data.map((sculpture ) => {
        return {
          id    : sculpture.attributes.model_id,
          theme : sculpture.attributes.theme.data.attributes.name,
          name  : sculpture.attributes.name,
          slug  : sculpture.attributes.slug,
          image : sculpture.attributes.image.data.attributes
        };
      });
      setSculpturesData(sculptures);
    };

    fetchSculptures();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSculpturesFetcher;
