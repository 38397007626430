import React, {
  useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Howl, Howler } from 'howler';
import { useRouter } from 'next/router.js';
import   { useAppStore, useCanvasStore, useWindowSizeStore } from '@/store';
import device from '@/utils/device.js';
import * as Styled from './BackgroundAudio.styled.js';

const propTypes = {};
const defaultProps = {};

const BackgroundAudio = (props) => {
  const router = useRouter();
  const {
    sceneName
  }
  = useCanvasStore((state) =>
    ({
      sceneName : state.sceneName
    }));
  const {
    isUserEntered,
    soundOn
  } = useAppStore(state => ({
    isUserEntered : state.isUserEntered,
    soundOn       : state.soundOn
  }));
  const introAudio = useMemo(() => new Howl({
    src      : ['/audio/placeholder.webm', '/audio/placeholder.mp3'],
    autoplay : false,
    loop     : true,
    volume   : 0.5,
    preload  : true,
    html5    : true
  }), []);

  const seaWavesAudio = useMemo(() => new Howl({
    src      : ['/audio/seaWaves.webm', '/audio/seaWaves.mp3'],
    autoplay : false,
    loop     : true,
    volume   : 0.2,
    preload  : true,
    html5    : true
  }), []);
  const beachAudio = useMemo(() => new Howl({
    src      : ['/audio/beach.webm', '/audio/beach.mp3'],
    autoplay : false,
    loop     : true,
    preload  : true,
    html5    : true,
    volume   : 0.1
  }), []);

  const amphitheatreAudio = useMemo(() => new Howl({
    src      : ['/audio/amphitheatre.webm', '/audio/amphitheatre.mp3'],
    autoplay : false,
    loop     : true,
    volume   : 0.2,
    preload  : true,
    html5    : true
  }), []);

  useEffect(() => {
    if (isUserEntered) {
      if (!introAudio.playing())
        introAudio.play();

      if (sceneName === 'intro') {
        if (!seaWavesAudio.playing())
          seaWavesAudio.play();
      }

      if (sceneName === 'amphi-zoom-out') {
        if (!beachAudio.playing())
          beachAudio.play();
        if (!amphitheatreAudio.playing())
          amphitheatreAudio.play();

        if (seaWavesAudio.playing()) {
          seaWavesAudio.fade(0.2, 0.0, 1);
        }
      }

      if (sceneName === 'amphitheatre') {
        if (!amphitheatreAudio.playing())
          amphitheatreAudio.play();

        if (beachAudio.playing()) {
          beachAudio.fade(0.1, 0.02, 1);
        }
        amphitheatreAudio.volume(0.5);
      }

      if ((sceneName === 'carousel') || (router.route !== '/' && router.route !== '/amphitheatre')) {
        if (beachAudio.playing()) {
          beachAudio.stop();
        }
        if (amphitheatreAudio.playing()) {
          amphitheatreAudio.stop();
        }
        if (seaWavesAudio.playing()) {
          seaWavesAudio.stop();
        }
      }
    }

    if (soundOn) {
      if (!device.detection.isMobile) {
        Howler.volume(1);
      }
    } else {
      if (!device.detection.isMobile) {
        Howler.volume(0);
      } else {
        Howler.stop();
      }
    }
  }, [introAudio, sceneName, isUserEntered, seaWavesAudio, beachAudio, amphitheatreAudio, soundOn, router.route]);

  return (
    <Styled.Wrapper>
      BackgroundAudio
    </Styled.Wrapper>
  );
};

BackgroundAudio.propTypes = propTypes;
BackgroundAudio.defaultProps = defaultProps;

export default BackgroundAudio;
