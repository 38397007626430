import axios from 'axios';
import axiosRetry from 'axios-retry';

export const _axios = axios.create({
  timeout : 10000,
  baseURL : process.env.NEXT_PUBLIC_API_ENDPOINT, // Needs to be added in vite.config.js through EnvironmentPlugin
  headers : {
    'Content-Type' : 'application/json'
  }
});
axiosRetry(_axios, { retries : 2 });
