import styled from 'styled-components';
import { setTypography, flexCenter } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop, mediaTablet, mediaDesktopWide } from '@/styles/_responsive';

export const Wrapper = styled.div`
  ${flexCenter()};
  position: relative;
  width: 100%;
`;

export const Heading = styled.h1`
  ${setTypography('h1')};
  text-align: center;
  color: ${colors.gold};
  margin: 16rem 0;
  ${mediaDesktop(`
    margin-top: 0;
    margin-bottom: 24rem;
  `)}
`;

export const Subheading = styled.p`
  ${setTypography('theme')};
  color: ${colors.grey1};
  text-align: center;
  white-space: pre-wrap;
  ${mediaDesktop(`
    max-width: unset;
  `)}
  margin-bottom: ${(props) => props.$isIOS ? '0rem' : '24rem'};
`;

export const HeadingWrapper = styled.div`
  ${flexCenter()};

  ${mediaDesktop(`
    // flex-direction: column-reverse;
  `)};
`;

export const Description = styled.p`
  ${setTypography('detail')};
  color: ${colors.grey1};
  text-align: center;
  width: 85%;
  @media (max-width: 900px){
    width: 85%;
  }
  // @media (min-width: 900px) and (max-width: 1670px) {
  //   width: 50%;
  // }
  // @media (min-width: 900px) and (max-width: 1670px) {
  //   width: 50%;
  // }


  ${mediaDesktop(`
    width: 50%;
  `)}
  ${mediaDesktopWide(`
    width: 33%;
  `)}

`;
