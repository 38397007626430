import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { colors } from '@/styles/_theme.js';
import device from '@/utils/device.js';
import * as Styled from './Chip.styled.js';

const propTypes = {
  label   : PropTypes.string,
  picked  : PropTypes.bool,
  onClick : PropTypes.func
};

const defaultProps = {
  label   : '',
  picked  : false,
  onClick : () => {}
};

const Chip = (props) => {
  const {
    label, picked, onClick
  } = props;
  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    const chosen = !picked;
    onClick(chosen);
  };

  const handleMouseEnter = () => {
    !device.detection.isMobile && setHovered(true);
  };

  const handleMouseLeave = () => {
    !device.detection.isMobile && setHovered(false);
  };

  const pickedOrHovered = useMemo(() => picked || hovered, [picked, hovered]);
  const motionVariants = {
    wrapper      : { backgroundColor : pickedOrHovered ? colors.black : colors.white },
    innerWrapper : { borderColor : pickedOrHovered ? colors.grey2 : colors.beige },
    label        : { color : pickedOrHovered ? colors.white : colors.grey1 }
  };

  return (
    <Styled.Wrapper
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      variants={motionVariants}
      animate="wrapper"
      transition={{ duration : 0.2 }}
      as={motion.button}
      type="button"
    >
      <Styled.InnerWrapper
        variants={motionVariants}
        animate="innerWrapper"
        transition={{ duration : 0.2 }}
        as={motion.div}
      >
        <Styled.Label
          variants={motionVariants}
          animate="label"
          transition={{ duration : 0.2 }}
          as={motion.p}
        >
          {label}
        </Styled.Label>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

export default Chip;
