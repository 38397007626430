import styled from 'styled-components';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 27px 32px;
  justify-content: space-between;
  align-items: center;
  color: ${colors.grey4};
  flex-wrap: wrap;
  ${mediaDesktop(
    `
      padding: 40rem;
    `
  )}
`;

export const FooterLeft = styled.p`
  ${setTypography('detail')};
  color: ${colors.grey4};
  order: 2;
  ${mediaDesktop(
    `
    order: 1;
    `
  )}
`;

export const FooterCenter = styled.div`
  display: flex;
  ${setTypography('detail')};
  column-gap: 24px;
  width: 100%;
  order: 1;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 32px;

  ${mediaDesktop(
    `
      width: unset;
      margin-bottom: unset;
      order: 2;
      column-gap: 48rem;
    `
  )}
`;
export const LinkStyled = styled(motion.div)`
  a {
    color: ${colors.grey4};
    cursor: pointer;
    text-decoration: none;
  }
`;
export const FooterRight = styled.div`

  p {
    color: ${colors.grey4};
  }
  order: 3;
  ${mediaDesktop(
    `
      margin-bottom: unset;
    `
  )}
`;
