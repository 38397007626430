import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullSize } from '@/styles/_mixins';

export const Wrapper = styled(motion.div)`
  ${fullSize()}
  z-index: 3;
  opacity: 0.5;
  pointer-events:none;
`;
export const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const TopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 700rem;
  height: 700rem;
  background-image: radial-gradient(circle at center, #666666 0, rgba(0,0,0,0) 70%);
  transform: translate(-50%, -50%);
`;
export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 700rem;
  height: 700rem;
  background-image: radial-gradient(circle at center, #666666 0, rgba(0,0,0,0) 70%);
  transform: translate(50%, -50%);
`;
export const BotLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 700rem;
  height: 700rem;
  background-image: radial-gradient(circle at center, #666666 0, rgba(0,0,0,0) 70%);
  transform: translate(-50%, 50%);
`;
export const BotRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 700rem;
  height: 700rem;
  background-image: radial-gradient(circle at center, #666666 0, rgba(0,0,0,0) 70%);
  transform: translate(50%, 50%);
`;
