import create from 'zustand';
import shallow from 'zustand/shallow';
import { subscribeWithSelector } from 'zustand/middleware';
import urlQueries from '@/utils/url-queries';

export const sculpturesSliderStore = create(subscribeWithSelector((set) => {

  const defaultIndex = urlQueries.get('index') || 0;
  const isArMode = urlQueries.get('arMode') ? true :  false;
  return {
    slideTimelinePosition    : 0,
    setSlideTimelinePosition : (val) => set(() => ({ slideTimelinePosition : val })),

    currentSlide    : parseInt(defaultIndex, 10),
    setCurrentSlide : (val) => set(() => ({ currentSlide : val })),

    currentSlideData    : {},
    setCurrentSlideData : (obj) => set(() => ({ currentSlideData : { ...obj } })),

    sculpturesData    : [],
    setSculpturesData : (arr) => set(() => ({ sculpturesData : arr })),

    updateImmediately    : false,
    setUpdateImmediately : (val) => set(() => ({ updateImmediately : val })),

    scrollPosition    : 0,
    setScrollPosition : (val) => set(() => ({ scrollPosition : val })),

    arMode    : isArMode,
    setArMode : (val) => set(() => ({ arMode : val }))
  };
}));

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
const useSculpturesSliderStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return sculpturesSliderStore(cb, shallow);
    } else {
      return sculpturesSliderStore(cb);
    }
  } else {
    return sculpturesSliderStore;
  }
};

export default useSculpturesSliderStore;
