import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { colors } from '@/styles/_theme.js';
import { copy } from '@/locales/en.js';
import * as Styled from './CustomTextArea.styled.js';

const propTypes = {
  rest  : PropTypes.object,
  error : PropTypes.string
};
const defaultProps = {
  rest  : {},
  error : ''
};

const CustomTextArea = (props) => {
  const { error, ...rest } = props;

  const [textareaFocused, setTextareaFocused] = useState(false);

  return (
    <Styled.Wrapper>
      <Styled.Textarea
        initial={{ borderColor : colors.grey1 }}
        animate={{ borderColor : textareaFocused ? colors.gold : colors.grey3 }}
        transition={{ type : 'tween', duration : 0.4 }}
        onFocus={() => setTextareaFocused(true)}
        onBlur={() => setTextareaFocused(false)}
        placeholder={copy.SF_STEP3_TEXT_AREA_PLACEHOLDER}
        as={motion.textarea}
        {...rest}
      />
      {(error) && (
        <Styled.Description $hasError={error !== ''}>
          {error}
        </Styled.Description>
      )}
    </Styled.Wrapper>

  );
};

CustomTextArea.propTypes = propTypes;
CustomTextArea.defaultProps = defaultProps;

export default CustomTextArea;
