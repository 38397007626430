import styled from 'styled-components';
import { motion } from 'framer-motion';
import { flexCenter } from '@/styles/_mixins';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled(motion.div)`
  position: relative;
  ${flexCenter()};
  width: fit-content;
`;

export const Item = styled(motion.div)`
  width: 100%;
  text-align: center;
  position: relative;

`;
export const ItemWrapper = styled(motion.div)`
  width: 100%;
  text-align: center;
  position: relative;
`;
export const Dash = styled.div`
    position: absolute;
    left: -40rem;
    top: 0%;
    &:last-child {
      left: unset;
      right: -40rem;
    }
    svg {
      width: 24rem;
      height: 4rem;
    }
    ${mediaDesktop(
    `
      left: -68rem;
      svg {
        width: 36rem;
        height: 6rem;
      }
      &:last-child {
        left: unset;
        right: -68rem;
      }
    `
  )}

`;
