import styled from 'styled-components';
import { setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 15rem;
  pointer-events: auto;
`;

export const Label = styled.p`
  ${setTypography('detail')}
  color: ${props => props.theme === 'dark' ? colors.grey4 : props.theme === 'white' ? colors.silver : colors.grey1};
  text-transform: capitalize;
  padding-top: 3rem;
  line-height: 1em !important;
  user-select: none;
`;

export const List = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  column-gap: 10rem;
`;

export const ListItem = styled.li``;

export const CopyLinkWrapper = styled.div``;

export const CopyMessage = styled.div`
  ${setTypography('detail')};
  position: absolute;
  background-color: ${colors.grey1};
  padding: 7.5rem 15rem 5rem;
  line-height: 1 !important;
  width: max-content;
  border-radius: 20rem;
  display: none;
  color: ${colors.white};
  width: fit-content;
  right: 0;
  top: -40rem;
`;
