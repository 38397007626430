import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { colors } from '@/styles/_theme.js';
import * as Styled from './CustomInput.styled.js';

const propTypes = {
  id          : PropTypes.string,
  label       : PropTypes.string,
  description : PropTypes.string,
  error       : PropTypes.string,
  rest        : PropTypes.object
};
const defaultProps = {
  id          : '',
  label       : '',
  description : '',
  error       : '',
  rest        : {}
};

const CustomInput = (props) => {
  const {
    id, label, description, error, ...rest
  } = props;
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <Styled.Wrapper>
      <Styled.InputWrapper>
        <Styled.Label
          htmlFor={id}
          initial={{ color : colors.grey1 }}
          animate={{ color : error ? colors.red : (inputFocused ? colors.gold : colors.grey1) }}
          transition={{ type : 'tween', duration : 0.4 }}
          as={motion.label}
        >
          {label}
        </Styled.Label>
        <Styled.Input
          id={id}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          {...rest}
        />
      </Styled.InputWrapper>
      <Styled.Dividers>
        <Styled.Divider />
        <Styled.HighlightedDivider
          initial={{ width : 0 }}
          animate={{ width : inputFocused ? '100%' : 0 }}
          transition={{ type : 'tween', duration : 0.4 }}
          as={motion.div}
        />
      </Styled.Dividers>
      {(description || error) && (
        <Styled.Description $hasError={error !== ''}>
          {error ? error : description}
        </Styled.Description>
      )}
    </Styled.Wrapper>
  );
};

CustomInput.propTypes = propTypes;
CustomInput.defaultProps = defaultProps;

export default CustomInput;
