import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import SubmissionForm from '@/components/ui/SubmissionForm/SubmissionForm.jsx';
import useStorySubmissionStore from '@/store/_story-submission.js';
import gtag from '@/utils/gtag.js';
import { EVENTS } from '@/config/config.analytics.js';
import * as Styled from './StorySubmissionModal.styled.js';

const propTypes = {};
const defaultProps = {};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { }
};
const bgVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 1, transition : { duration : 1, ease : 'easeInOut' }
  },
  exit : { opacity : 0, transition : { duration : 1, ease : 'easeInOut' } }
};

const wrapperVariants = {
  initial : { x : '100%' },
  animate : {
    x : '0%', transition : { duration : 1, ease : 'easeInOut' }
  },
  exit : { x : '100%', transition : { duration : 1, ease : 'easeInOut' } }
};
const StorySubmissionModal = (props) => {
  const setStorySubmissionFormOpened = useStorySubmissionStore(state => state.setStorySubmissionFormOpened);

  useEffect(() => {
    gtag.trackEvent(EVENTS.ENTER_SUBMIT_STORY);
  }, []);

  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Background
        onClick={() => setStorySubmissionFormOpened(false)}
        as={motion.div}
        variants={bgVariants}
      />
      <Styled.SubmissionFormWrapper
        as={motion.div}
        variants={wrapperVariants}
      >
        <SubmissionForm />
      </Styled.SubmissionFormWrapper>
    </Styled.Wrapper>
  );
};

StorySubmissionModal.propTypes = propTypes;
StorySubmissionModal.defaultProps = defaultProps;

export default StorySubmissionModal;
