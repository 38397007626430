import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { copy } from '@/locales/en.js';
import { useWindowSizeStore } from '@/store';
import device from '@/utils/device.js';
import * as Styled from './IntroHeading.styled.js';

const propTypes = {
  subheading       : PropTypes.string,
  subheadingMobile : PropTypes.string
};
const defaultProps = {
  subheading       : '',
  subheadingMobile : ''
};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.4 } },
  exit    : { }
};
const IntroHeading = (props) => {
  const { subheading, subheadingMobile } = props;
  const HeadingAnimate = {
    initial : {
      opacity : 0, letterSpacing : '0.6em', lineHeight : 1.3
    },
    animate : {
      opacity       : 1,
      letterSpacing : '-0.02em',
      lineHeight    : 0.83,
      transition    : { duration : 1.4, ease : 'easeOut', delay : 1.3 }
    },
    exit : { opacity : 0 }
  };
  const textAnimate = {
    initial : {
      opacity : 0,  width : '120%', letterSpacing : '2.2em'
    },
    animate : {
      opacity       : 1,
      letterSpacing : '1.2em',
      width         : '80%',
      transition    : { duration : 1.4, ease : 'easeOut', delay : 1.4 }
    },
    exit : { opacity : 0 }
  };
  const subAnimate = {
    initial : {
      opacity : 0, letterSpacing : '2.2em',  width : '120%'
    },
    animate : {
      opacity       : 1,
      letterSpacing : '1.2em',
      width         : '80%',
      transition    : { duration : 1.4, ease : 'easeOut', delay : 1.6 }
    },
    exit : { opacity : 0 }
  };
  const descriptionAnimate = {
    initial : {
      opacity : 0, scaleX : 1.5, y : 30
    },
    animate : {
      opacity    : 1,
      scaleX     : 1,
      y          : 0,
      transition : { duration : 1, delay : 1.9, ease : 'easeOut' }
    },
    exit : { opacity : 0 }
  };

  return (

    <Styled.Wrapper
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      as={motion.div}
    >
      {/* {showText && (
        <> */}
      <Styled.HeadingWrapper>
        <Styled.Subheading
          variants={textAnimate}
          $isIOS = {device.detection.isiOS}
          as={motion.p}
          // dangerouslySetInnerHTML={{ __html : device.detection.isMobile ? subheadingMobile : subheading }}
        >
          {device.detection.isMobile ? subheadingMobile : subheading}
        </Styled.Subheading>

        <Styled.Heading
          variants={HeadingAnimate}
          as={motion.h1}
        >
          {copy.INTRO_HEADING[0]}
          <br />
          {copy.INTRO_HEADING[1]}
        </Styled.Heading>
      </Styled.HeadingWrapper>

      <Styled.Description
        variants={descriptionAnimate}

        as={motion.p}
      >
        {copy.INTRO_DESCRIPTION}
      </Styled.Description>
      {/* </>
      )} */}
    </Styled.Wrapper>
  );
};

IntroHeading.propTypes = propTypes;
IntroHeading.defaultProps = defaultProps;
export default IntroHeading;
