import React from 'react';
import { padStart } from 'lodash';
import PropTypes from 'prop-types';
import * as Styled from './SubmissionFormPagination.styled.js';

const propTypes = {
  currentPage : PropTypes.number,
  maxPages    : PropTypes.number
};
const defaultProps = {
  currentPage : 1,
  maxPages    : 3
};

const SubmissionFormPagination = (props) => {
  const { currentPage, maxPages } = props;

  return (
    <Styled.Wrapper>
      <Styled.Label>{padStart(currentPage, 2, '0')}</Styled.Label>
      <Styled.Divider>&nbsp;/&nbsp;</Styled.Divider>
      <Styled.Label>{padStart(maxPages, 2, '0')}</Styled.Label>
    </Styled.Wrapper>
  );
};

SubmissionFormPagination.propTypes = propTypes;
SubmissionFormPagination.defaultProps = defaultProps;

export default SubmissionFormPagination;
