import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullSize, flexCenter } from '@/styles/_mixins';
import { colors, layout } from '@/styles/_theme';

export const Wrapper = styled(motion.div)`
  position: relative;
  ${fullSize()};
  ${flexCenter()};
  padding: 40rem;
  z-index: ${layout.zIndex.menuOverlay};
`;

export const Background = styled(motion.div)`
  ${fullSize()};
  background: ${colors.black};
`;
export const GoldBackground = styled(motion.div)`
  ${fullSize()};
  background: ${colors.gold};
`;
