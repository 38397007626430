import React from 'react';
import PropTypes from 'prop-types';
import { useAppStore } from '@/store';
import * as Styled from './MenuButton.styled.js';

const propTypes = {
  className : PropTypes.string
};
const defaultProps = {
  className : ''
};

const MenuButton = (props) => {
  const { className, ...rest } = props;

  const { menuOpened, setMenuOpened } = useAppStore(state => ({
    menuOpened    : state.menuOpened,
    setMenuOpened : state.setMenuOpened
  }));

  const handleClick = () => {
    setMenuOpened(!menuOpened);
  };

  return (
    <Styled.Wrapper>
      <Styled.IconWrapper
        icon="hamburger"
        onClick={handleClick}
        className={className}
        menuOpen={menuOpened}
      />
    </Styled.Wrapper>
  );
};

MenuButton.propTypes = propTypes;
MenuButton.defaultProps = defaultProps;

export default MenuButton;
