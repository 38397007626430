import create from 'zustand';
import shallow from 'zustand/shallow';

export const canvasStore = create((set) => ({
  // All scene names : "intro" | "intro-text" | "amphi-zoom-out" | "amphitheatre" | "carousel" | "sculpture-details"
  sceneName        : 'intro',
  setSceneName     : (val) => set(() => ({ sceneName : val })),
  nextSculpture    : false,
  setNextSculpture : (val) => set(() => ({ nextSculpture : val }))
}));

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
const useCanvasStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return canvasStore(cb, shallow);
    } else {
      return canvasStore(cb);
    }
  } else {
    return canvasStore();
  }
};

export default useCanvasStore;
