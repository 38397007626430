import '@/utils/wdyr';
import { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import Head from 'next/head';
import { get } from 'lodash';
import DrawSVG from 'gsap/dist/DrawSVGPlugin';
import dynamic from 'next/dynamic';
import { _axios } from '@/utils/axios-helper.js';
import { ENDPOINT_SCULPTURES_GET } from '@/config/config.endpoints.js';
import GlobalStyles from '@/styles/_global-styles';
import fonts from '@/styles/_fonts';
import useWindowResizeListener from '@/hooks/use-window-resize-listener';
import useUrlQueries from '@/hooks/use-url-queries';
import useNonFunctionals from '@/hooks/use-non-functionals';
import NonFunctionals from '@/components/ui/NonFunctionals/NonFunctionals';
import useThemesFetcher from '@/hooks/use-themes-fetcher';
import useSculpturesFetcher from '@/hooks/use-sculptures-fetcher';
import useIsDeviceSupported from '@/hooks/use-is-device-supported';
import Loader from '@/components/ui/Loader/Loader';
import {
  useAppStore,
  useStorySubmissionStore
} from '@/store';
import Header from '@/components/ui/Header/Header';
import HeaderCenter from '@/components/ui/Header/HeaderCenter';
import MenuOverlay from '@/components/ui/MenuOverlay/MenuOverlay';
import DesktopQrCode from '@/components/ui/DesktopQrCode/DesktopQrCode';
import NotSupportedOverlay from '@/components/ui/NotSupportedOverlay/NotSupportedOverlay';
//import ThreeCanvas from '@/components/canvas/ThreeCanvas/ThreeCanvas';
import StorySubmissionModal from '@/components/ui/StorySubmissionModal/StorySubmissionModal';
import BackgroundAudio from '@/components/ui/BackgroundAudio/BackgroundAudio';
import useThreeLoader from '@/hooks/use-three-loader';
import device from '@/utils/device';
import CornerShadows from '@/components/ui/CornerShadows/CornerShadows';
import gtag from '@/utils/gtag';
import * as Styled from './_app.styled';

const Footer = dynamic(() => import('@/components/ui/Footer/Footer'), {
  ssr : false
});
const propTypes = {
  Component : PropTypes.any,
  pageProps : PropTypes.object,
  router    : PropTypes.object
};

const defaultProps = {
  Component : null,
  pageProps : {},
  router    : {}
};

const ThreeCanvas = dynamic(() =>
  import('@/components/canvas/ThreeCanvas/ThreeCanvas')
);

function App({ Component, pageProps, router }) {
  useWindowResizeListener();
  useUrlQueries();
  useNonFunctionals();
  useThemesFetcher();
  useSculpturesFetcher();
  const {
    isAndroid
  } = device.detection;
  const isThreeLoaded = useThreeLoader();
  const { isDeviceSupported, displayText, showBorderedLayout } = useIsDeviceSupported();

  const {
    menuOpened, qrModalOpened, setIsUserEntered, setScrollRef
  } = useAppStore(state => ({
    menuOpened       : state.menuOpened,
    qrModalOpened    : state.qrModalOpened,
    setIsUserEntered : state.setIsUserEntered,
    setScrollRef     : state.setScrollRef
  }));
  const scroll = useRef();
  const storySubmissionFormOpened = useStorySubmissionStore(
    state => state.storySubmissionFormOpened
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info(
      'process.env.NEXT_PUBLIC_API_ENDPOINT:',
      process.env.NEXT_PUBLIC_API_ENDPOINT
    );
    // eslint-disable-next-line no-console
    console.info('process.env.APP_URL', process.env.APP_URL);
    gsap.registerPlugin(DrawSVG);
    setScrollRef(scroll);
  }, [setScrollRef]);

  useEffect(() => {
    let prevRoutePath = '';
    function handleRouteChange(routePath) {
      const routeName = routePath;
      if (prevRoutePath !== routePath) {
        gtag.trackPage(routeName);
      }
      prevRoutePath = routePath;
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    // Initial call
    handleRouteChange(router.asPath);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const showCanvas = router =>
    router.route === '/sculptures' ||
        router.route === '/trireme' ||
        router.route === '/' ||
        router.route === '/amphitheatre' ||
        (router.route.split('/').includes('sculptures') && router.query);

  const isNotHomePage = router.route !== '/';
  const isAmphitheatrePage = router.route === '/amphitheatre';
  const isDetailsPage = router.route === '/sculptures/[slug]';

  useEffect(() => {
    if (isNotHomePage) {
      setIsUserEntered(true);
    }
  }, [isNotHomePage, setIsUserEntered]);

  return (
    <Styled.App
      $isDetailsPage={isDetailsPage}
      ref={scroll}
    >
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          property="og:image"
          content={`${process.env.APP_URL}/og-image.png`}
          key="og:image"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta
          name="twitter:image"
          content={`${process.env.APP_URL}/og-image.png`}
          key="twitter:image"
        />
      </Head>

      <style dangerouslySetInnerHTML={{ __html : fonts }} />

      <GlobalStyles />

      {showCanvas(router) && (
        <ThreeCanvas />
      )}

      <AnimatePresence exitBeforeEnter>
        {router.route === '/stories' && (
          <Component {...pageProps} key={router.route} />
        )}
        {router.route !== '/stories' && (
          <Component {...pageProps} key={router.asPath} />
        )}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {storySubmissionFormOpened && <StorySubmissionModal />}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {isAmphitheatrePage && <CornerShadows />}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {menuOpened && <MenuOverlay />}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {<Header />}
        {isNotHomePage && <HeaderCenter />}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter>
        {isNotHomePage && !menuOpened && <Footer />}
      </AnimatePresence>

      {qrModalOpened && <DesktopQrCode />}

      {!isDeviceSupported && <NotSupportedOverlay displayText={displayText} showBorderedLayout={showBorderedLayout} />}

      <AnimatePresence exitBeforeEnter>
        {!isThreeLoaded && <Loader />}
      </AnimatePresence>
      <BackgroundAudio />
      <NonFunctionals />
    </Styled.App>
  );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
