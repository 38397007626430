import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { copy } from '@/locales/en.js';
import { useStorySubmissionStore } from '@/store';
import CustomInput from '@/components/ui/CustomInput/CustomInput.jsx';
import CustomTextArea from '@/components/ui/CustomTextArea/CustomTextArea.jsx';
import Button from '@/components/ui/Button/Button.jsx';
import { validateStoryContent, validateStoryTitle } from '@/utils/validator.js';
import * as Styled from './SubmissionFormStepThree.styled.js';

const propTypes = {
  onSubmit : PropTypes.func
};

const defaultProps = {
  onSubmit : () => {}
};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { opacity : 0, transition : { duration : 0.25 } }
};
const variants = {
  initial : {
    y : '20%', letterSpacing : '0.2em', opacity : 0
  },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const ctaVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 0.99, transition : {  duration : 0.8, ease : 'linear' }
  },
  exit : { opacity : 0 }
};
const SubmissionFormStepThree = (props) => {
  const { onSubmit } = props;
  const [titleIsInvalid, setTitleIsInvalid] = useState(false);
  const [storyIsInvalid, setStoryIsInvalid] = useState(false);
  const {
    storyTitle,
    storyContent,
    setStoryTitle,
    setStoryContent
  } = useStorySubmissionStore(state => ({
    storyTitle      : state.storyTitle,
    storyContent    : state.storyContent,
    setStoryTitle   : state.setStoryTitle,
    setStoryContent : state.setStoryContent
  }));

  const handleStoryTitleInput = (event) => {
    setStoryTitle(event.target.value);
    setTitleIsInvalid(false);
  };

  const handleStoryContentInput = (event) => {
    setStoryContent(event.target.value);
    setStoryIsInvalid(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const storyValidation = validateStoryContent(storyContent);
    const titleValidation = validateStoryTitle(storyTitle);
    if (titleValidation.error || storyValidation.error){
      titleValidation.error && setTitleIsInvalid(true);
      storyValidation.error && setStoryIsInvalid(true);
      return;
    }

    onSubmit();
  };

  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Heading
        as={motion.h3}
        variants={variants}
      >
        {copy.SF_STEP3_HEADING}
      </Styled.Heading>
      <Styled.Form
        onSubmit={handleSubmit}
        noValidate
        as={motion.form}
        variants={ctaVariants}
        id="formthree"
      >
        <CustomInput
          id="submissionFormStoryTitle"
          label={copy.SF_STEP3_TITLE_LABEL}
          type="text"
          onInput={handleStoryTitleInput}
          value={storyTitle}
          error={titleIsInvalid ? copy.SF_STEP3_TITLE_ERROR : ''}
          required
        />
        <CustomTextArea
          onInput={handleStoryContentInput}
          value={storyContent}
          error={storyIsInvalid ? copy.SF_STEP3_STORY_CONTENT_ERROR : ''}
          required
        />

      </Styled.Form>
      <Styled.ButtonWrapper
        variants={ctaVariants}
      >
        <Button type="submit" form="formthree">
          {copy.SF_STEP3_CTA}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

SubmissionFormStepThree.propTypes = propTypes;
SubmissionFormStepThree.defaultProps = defaultProps;

export default SubmissionFormStepThree;
