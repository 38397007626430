import styled from 'styled-components';
import hexRgb from 'hex-rgb';
import { fullSize, flexCenter, setTypography } from '@/styles/_mixins';
import IconButton from '@/components/ui/IconButton/IconButton';
import { colors, layout } from '@/styles/_theme';

const c = hexRgb(colors.black);

export const Wrapper = styled.div`
  position: relative;
  ${fullSize()};
  ${flexCenter()};
  display: flex;
  z-index: ${layout.zIndex.desktopQrCode};
`;

export const Background = styled.div`
  ${fullSize()}
  background-color: rgba(${c.red}, ${c.green}, ${c.blue}, 0.8);
  backdrop-filter: blur(5px);
`;

export const Container = styled.div`
  position: relative;
  width: 553rem;
  height: 424rem;
  background: ${colors.silver};
  ${flexCenter()};
`;

export const Heading = styled.h3`
  ${setTypography('h3')};
  color: ${colors.gold};
`;
export const Subheading = styled.p`
  ${setTypography('paragraphWithoutIndent')};
  margin-top: 16rem;
  margin-bottom: 40rem;
  color: ${colors.grey1};
`;
export const Close = styled(IconButton)`
  position: absolute;
  right: 40rem;
  top: 40rem;
  svg {
    width: 10rem;
    height: 10rem;
  }
`;
