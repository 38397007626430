import { kebabCase } from 'lodash';
import hexRgb from 'hex-rgb';
import { colors, fonts } from './_theme';
import { mediaDesktop, mediaTablet } from './_responsive';

export const flexCenter = (direction = 'column') => {
  return `
    display: flex;
    flex-direction: ${direction};
    justify-content: center;
    align-items: center;
  `;
};

const fullSizeDefaultOptions = {
  position   : 'absolute',
  centered   : false,
  scrollable : false
};
export const fullSize = (options = fullSizeDefaultOptions) => {
  const {
    position = fullSizeDefaultOptions.position,
    centered = fullSizeDefaultOptions.centered,
    scrollable = fullSizeDefaultOptions.scrollable
  } = options;

  return `
    position: ${position};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    ${centered ? flexCenter() : ``}
    ${scrollable ? `overflow-y: scroll;` : ``}
  `;
};

export const setTypography = (scale = 'heading1', locale = 'en', isImportant = false) => `
  ${Object.entries(fonts.scale[scale])
    .map(([key, value]) => {
      const isRemUnit = ['fontSize', 'wordSpacing', 'textIndent'].includes(key);
      const isEmUnit = ['letterSpacing', 'lineHeight'].includes(key);
      const isObject = ['fontSize', 'lineHeight', 'textIndent'].includes(key);
      const isFontFamily = ['fontFamily'].includes(key);
      const positiveLetterSpacing = key === 'letterSpacing' && value > 0;

      const parsedKey = kebabCase(key);
      const parsedValue = isObject
        ? (value).phone
        : isFontFamily
          ? fonts.family[typeof value === 'object' ? (value[locale] || value.default) : value]
          : value;
      const parsedUnit = isRemUnit && value ? 'rem' : ((isEmUnit && value) ? 'em' : '');

      if (positiveLetterSpacing) {
        return `letter-spacing: ${value}em; margin-right: -${value}em;`;
      } else {
        return `${parsedKey}: ${parsedValue}${parsedUnit}${isImportant ? ' !important' : ''};`;
      }
    })
    .join('\n')}

  ${mediaTablet(`
    ${Object.entries(fonts.scale[scale])
    .filter(([key]) => ['fontSize', 'lineHeight', 'textIndent'].includes(key))
    .map(([key, value]) => {
      const isRemUnit = ['fontSize', 'wordSpacing', 'textIndent'].includes(key);
      const parsedKey = kebabCase(key);
      const parsedUnit = isRemUnit && value ? 'rem' : '';
      const positiveLetterSpacing = key === 'letterSpacing' && value > 0;

      if (positiveLetterSpacing) {
        return `letter-spacing: ${value}em; margin-right: -${value}em;`;
      } else {
        return `${parsedKey}: ${(value).tablet}${parsedUnit}${isImportant ? ' !important' : ''};`;
      }
    })
    .join('\n')}
  `)}

  ${mediaDesktop(`
    ${Object.entries(fonts.scale[scale])
    .filter(
      ([key, value]) =>
        ['fontSize', 'lineHeight', 'textIndent'].includes(key) &&
                value.desktop !== undefined
    )
    .map(([key, value]) => {
      const isRemUnit = ['fontSize', 'wordSpacing', 'textIndent'].includes(key);
      const parsedKey = kebabCase(key);
      const parsedUnit = isRemUnit && value ? 'rem' : '';
      const positiveLetterSpacing = key === 'letterSpacing' && value > 0;

      if (positiveLetterSpacing) {
        return `letter-spacing: ${value}em; margin-right: -${value}em;`;
      } else {
        return `${parsedKey}: ${(value).desktop}${parsedUnit}${isImportant ? ' !important' : ''};`;
      }
    })
    .join('\n')}
  `)}
`;

export const ctaDropShadow = () => {
  const c = hexRgb(colors.grey1);
  return `
    filter: drop-shadow(0.1px 16rem 32rem rgba(${c.red}, ${c.green}, ${c.blue}, 0.15));
  `;
};

export const genericFormStyles = () => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50rem;
`;
