import styled from "styled-components";
import { motion } from "framer-motion";
import { colors, layout } from "@/styles/_theme";
import { mediaDesktop } from "@/styles/_responsive";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${layout.spacing.headerPadding.phone};
  z-index: ${layout.zIndex.header};
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  ${mediaDesktop(`
    padding: ${layout.spacing.headerPadding.desktop};
  `)}
`;
export const WrapperCenter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${layout.spacing.headerPadding.phone};
  z-index: ${layout.zIndex.header - 1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  ${mediaDesktop(`
    padding: ${layout.spacing.headerPadding.desktop};
  `)}
`;
export const MenuButtonWrapper = styled.div`
  pointer-events: auto;
  pointer-events: ${(props) =>
    props.$isNotHomePage ? "auto" : "none"} !important;
  visibility: ${(props) =>
    props.$isNotHomePage ? "visible" : "hidden"} !important;
`;

export const HomeReturnWrapper = styled(motion.div)`
  pointer-events: auto;
`;
export const SculptureReturnWrapper = styled.div`
  pointer-events: auto;
  a {
    color: ${colors.white};
  }
  ${mediaDesktop(`
    display: none;
  `)}
`;

export const VolumeButtonWrapper = styled.div`
  pointer-events: auto;
  z-index: 10;
`;
