import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 80%;
  width: 60%;
  height: 60%;

  svg {
    width: 400% !important;
    height: 400% !important;
  }
`;
