import styled from 'styled-components';
import { motion } from 'framer-motion';
import { setTypography, ctaDropShadow } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop, mediaTablet } from '@/styles/_responsive';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10rem;
  ${mediaDesktop(`
    width: 100%;
    max-width: 550rem;
  `)}
`;

export const Label = styled(motion.p)`
  ${setTypography('theme')}
  color: ${colors.grey1};
  margin-bottom: 10rem;
`;
export const ChipsWrapper = styled(motion.div)`
  width: 100%;
  pointer-events: auto;
  scroll-behavior: smooth;
`;
export const Chips = styled.div`
  will-change: transform;
  width: fit-content;
  display: flex;
  padding: 0 50rem;
  row-gap: 8rem;
  column-gap: 4rem;
  ${ctaDropShadow()};
  scrollbar-width: none;
  &::-webkit-scrollbar {
      display: none;
  }
  ${mediaTablet(`
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    overflow-x: unset;
    filter: none;
  `)}
`;

export const Error = styled.p`
  ${setTypography('detail')};
  color: ${colors.red};
  margin-top: 10rem;
`;
