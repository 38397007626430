import { useEffect } from 'react';
import { boolean } from 'boolean';
import { useUrlQueriesStore } from '@/store';
import urlQueries from '@/utils/url-queries';

const useUrlQueries = () => {
  const setDebug = useUrlQueriesStore(state => state.setDebug);

  useEffect(() => {
    setDebug(boolean(urlQueries.get('debug')));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useUrlQueries;
