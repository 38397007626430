import React from 'react';
import PropTypes from 'prop-types';
import Shadow from '@/assets/svgs/circle-shadow.svg';
import * as Styled from './CornerShadows.styled.js';

const propTypes = {};
const defaultProps = {};
const variants = {
  initial : { opacity : 0 },
  animate : { opacity : 0.5 },
  exit    : { opacity : 0 }
};
const CornerShadows = (props) => {
  return (
    <Styled.Wrapper
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.InnerWrapper>

        <Styled.TopLeft>
        </Styled.TopLeft>
        <Styled.TopRight>
        </Styled.TopRight>
        <Styled.BotLeft>
        </Styled.BotLeft>
        <Styled.BotRight>
        </Styled.BotRight>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

CornerShadows.propTypes = propTypes;
CornerShadows.defaultProps = defaultProps;

export default CornerShadows;
