import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullSize, setTypography, flexCenter } from '@/styles/_mixins';
import { colors, layout } from '@/styles/_theme';
import { mediaDesktop, mediaTablet } from '@/styles/_responsive';
import IntroHeading from '@/components/ui/IntroHeading/IntroHeading';

export const App = styled.div`
  ${fullSize({})}
  overflow-y: ${props => props.$isDetailsPage ? 'auto' : 'hidden'};
  overflow-x: hidden;

`;
