import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.button`
  position: relative;
  border-radius: 50em;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => {
    if (props.$theme === 'grey') return `${colors.grey5}`;
    else if (props.$theme === 'silver') return `${colors.silver}`;
    else return `${colors.white}`;
  }};
  cursor: pointer;

  ${props => {
    if (props.$theme === 'white') {
      return `
          ${Icon} {
            border: 1px solid ${colors.beige};
          }
        `;
    } else if (props.$theme === 'silver') {
      return `
          ${Icon} {
            border: 1px solid ${colors.grey5};
          }
        `;
    }
  }}
  ${props => {
    if (props.$size === 'small') {
      return `
        width: 28px;
        height: 28px;
        ${mediaDesktop(`
          width: 28rem;
          height: 28rem;
        `)}
      `;
    } else {
      return `
        width: 48px;
        height: 48px;
        ${mediaDesktop(`
          width: 48rem;
          height: 48rem;
        `)}
      `;
    }
  }}
`;

export const CloseAnimated = styled(motion.span)`
  position: absolute;

`;

export const Icon = styled.span`
  position: relative;
  border: 1px solid ${colors.grey4};
  border-radius: 50em;
  background: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  ${props => {
    if (props.$size === 'small') {
      return `
        width: 24px;
        height: 24px;
        svg {
          width: 10px;
          height: 10px;
        }
        ${mediaDesktop(`
          width: 24rem;
          height: 24rem;
          svg {
            width: 10rem;
            height: 10rem;
          }
        `)}
      `;
    } else {
      return `
        width: 40px;
        height: 40px;
        svg {
          width: 18px;
          height: 18px;
        }
        ${mediaDesktop(`
          width: 40rem;
          height: 40rem;
          svg {
            width: 18rem;
            height: 18rem;
          }
        `)}
      `;
    }
  }}

`;

export const DummyBorder = styled.div`
  content: "";
  position: absolute;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  border-radius: 24px;
  border: 1rem solid ${colors.grey1};
  z-index: 10;
  transition: all .5s;
  animation: clip 0.5s linear forwards;
  @keyframes clip {
    0% {
      clip-path: inset(90% 50% 0 0);
    }
    20% {
      clip-path: inset(0 50% 50% 0);
    }
    40% {
      clip-path: inset(0 0 50% 50%);
    }
    60% {
      opacity: 1;
      clip-path: inset(50% 0 0 50%);
    }
    75% {
      opacity: 0.9;
      clip-path: inset(95% 0 0 50%);
    }
    100%{
      opacity: 0;
      clip-path: inset(95% 45% 0 50%);
    }
}
`;
