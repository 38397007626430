const fonts = `
  /*
  * Peskia
  */
  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-Bold.woff2') format('woff2'),
        url('/fonts/Peskia/Peskia-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-SemiBold.woff2') format('woff2'),
         url('/fonts/Peskia/Peskia-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-BoldOblique.woff2') format('woff2'),
         url('/fonts/Peskia/Peskia-BoldOblique.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: block;
  }

  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-Regular.woff2') format('woff2'),
         url('/fonts/Peskia/Peskia-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-Oblique.woff2') format('woff2'),
         url('/fonts/Peskia/Peskia-Oblique.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: block;
  }

  @font-face {
    font-family: 'Peskia';
    src: url('/fonts/Peskia/Peskia-SemiBoldOblique.woff2') format('woff2'),
         url('/fonts/Peskia/Peskia-SemiBoldOblique.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: block;
  }

  /*
  * SuisseIntl
  */
  @font-face {
    font-family: 'SuisseIntl';
    src: url('/fonts/SuisseIntl/SuisseIntl-Regular.woff2') format('woff2'),
         url('/fonts/SuisseIntl/SuisseIntl-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'SuisseIntl';
    src: url('/fonts/SuisseIntl/SuisseIntl-Light.woff2') format('woff2'),
         url('/fonts/SuisseIntl/SuisseIntl-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  /*
   * Cardo
   */
  @font-face {
    font-family: 'Cardo';
    src: url('/fonts/Cardo/Cardo-Bold.woff2') format('woff2'),
         url('/fonts/Cardo/Cardo-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Cardo';
    src: url('/fonts/Cardo/Cardo-Regular.woff2') format('woff2'),
         url('/fonts/Cardo/Cardo-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Cardo';
    src: url('/fonts/Cardo/Cardo-Italic.woff2') format('woff2'),
         url('/fonts/Cardo/Cardo-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: block;
  }
`;

export const fontFamilies = {
  peskia     : 'peskia',
  suisseIntl : 'suisseIntl',
  cardo      : 'cardo'
};

export default fonts;
