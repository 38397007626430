import styled from 'styled-components';
import { setTypography } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';
import { mediaDesktop } from '@/styles/_responsive';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12rem;
  column-gap: 15rem;
  ${mediaDesktop(`
    margin-bottom: 15rem;
  `)}
`;

export const Label = styled.label`
  ${setTypography('paragraphWithoutIndent')};
`;

export const Input = styled.input`
  ${setTypography('paragraphWithoutIndent')};
  flex: 1;
  color: ${colors.grey1};
  border: none;
  outline: none;
  caret-color: ${colors.gold};
  background-color: transparent;
  width: 60%;
  ${mediaDesktop(`
    width: unset;
  `)}
`;

export const Dividers = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.grey3};
`;

export const HighlightedDivider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: ${colors.gold};
`;

export const Description = styled.p`
  ${setTypography('detail')};
  color: ${props => props.$hasError ? colors.red : colors.grey2};
  margin-top: 10rem;
`;
