import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useRouter } from 'next/router.js';
import PropTypes from 'prop-types';
import PaddedBorders from '@/components/ui/PaddedBorders/PaddedBorders.jsx';
import { colors } from '@/styles/_theme.js';
import * as Styled from './NotSupportedOverlay.styled.js';

const propTypes = {
  displayText        : PropTypes.object,
  showBorderedLayout : PropTypes.bool
};

const defaultProps = { showBorderedLayout : false };

const NotSupportedOverlay = ({ displayText, showBorderedLayout }) => {
  const router = useRouter();

  const qrModalLink = process.env.APP_URL + router.asPath;

  const BorderedLayout = (() => {
    return (
      <>
        <Styled.Background transparentBlur />

        <Styled.Container fixedSize >
          <PaddedBorders />
          {
            !!displayText.heading && <Styled.Heading>{displayText.heading}</Styled.Heading>
          }

          {
            !!displayText.subheading && <Styled.Subheading>{displayText.subheading}</Styled.Subheading>
          }

          <QRCodeSVG
            value={qrModalLink}
            size={80}
            bgColor="transparent"
            fgColor={colors.grey1}
          />

        </Styled.Container>
      </>
    );
  });

  const NoBorderLayout = () => {
    return (
      <>
        <Styled.Container>
          {
            !!displayText.heading && <Styled.Heading>{displayText.heading}</Styled.Heading>
          }
        </Styled.Container>
      </>
    );
  };

  return (
    <Styled.Wrapper>
      {showBorderedLayout ? <BorderedLayout /> : <NoBorderLayout />}
    </Styled.Wrapper>
  );
};

NotSupportedOverlay.propTypes = propTypes;
NotSupportedOverlay.defaultProps = defaultProps;

export default NotSupportedOverlay;
