import React from 'react';
import PropTypes from 'prop-types';
import useAppStore from '@/store/_app.js';
import IconButton from '@/components/ui/IconButton/IconButton.jsx';
import * as Styled from './SoundButton.styled.js';

const propTypes = {};
const defaultProps = {};

const SoundButton = (props) => {
  const { setSoundOn, soundOn } = useAppStore(state => ({
    setSoundOn : state.setSoundOn,
    soundOn    : state.soundOn
  }));

  const handleClick = () => {
    setSoundOn(!soundOn);
  };

  return (
    <Styled.Wrapper>
      <IconButton
        icon={soundOn ? 'curve' : 'line'}
        onClick={handleClick}
      />
    </Styled.Wrapper>
  );
};

SoundButton.propTypes = propTypes;
SoundButton.defaultProps = defaultProps;

export default SoundButton;
