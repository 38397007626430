import hexRgb from 'hex-rgb';
import styled from 'styled-components';
import { fullSize } from '@/styles/_mixins';
import { mediaDesktop } from '@/styles/_responsive';
import { colors, layout } from '@/styles/_theme';

const c = hexRgb(colors.black);

export const Wrapper = styled.div`
  ${fullSize()}
  display: flex;
  justify-content: flex-end;
  z-index: ${layout.zIndex.storySubmissionModal};
`;

export const Background = styled.div`
  ${fullSize()}
  background-color: rgba(${c.red}, ${c.green}, ${c.blue}, 0.8);
  backdrop-filter: blur(2px);
`;

export const SubmissionFormWrapper = styled.div`
  width: 100%;
  ${mediaDesktop(`
    width: 50vw;
  `)}
`;
