import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { copy } from '@/locales/en.js';
import CustomCheckbox from '@/components/ui/CustomCheckbox/CustomCheckbox.jsx';
import Button from '@/components/ui/Button/Button.jsx';
import { useStorySubmissionStore } from '@/store';
import { _axios } from '@/utils/axios-helper.js';
import { ENDPOINT_STORIES_POST } from '@/config/config.endpoints.js';
import device from '@/utils/device';
import { validateFiles } from '@/utils/validator.js';
import gtag from '@/utils/gtag.js';
import { EVENTS } from '@/config/config.analytics.js';
import * as Styled from './SubmissionFormStepFour.styled.js';

const propTypes = {
  onSubmit : PropTypes.func
};
const defaultProps = {
  onSubmit : () => {}
};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { opacity : 0, transition : { duration : 0.25 } }
};
const variants = {
  initial : {
    y : '20%', letterSpacing : '0.2em', opacity : 0
  },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const ctaVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 0.99, transition : { duration : 0.8, ease : 'linear' }
  },
  exit : { opacity : 0 }
};
const SubmissionFormStepFour = (props) => {
  const { onSubmit } = props;

  const [privacyPolicyIsChecked, setPrivacyPolicyIsChecked] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [hasFileError, setHasFileError] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const {
    name,
    email,
    storyTitle,
    storyContent,
    storyImageFiles,
    storyPrivacyPolicyAgreed,
    setStoryPrivacyPolicyAgreed,
    setStoryImageFiles,
    submissionChosenThemes
  } = useStorySubmissionStore(state => ({
    name                        : state.name,
    email                       : state.email,
    storyTitle                  : state.storyTitle,
    storyContent                : state.storyContent,
    storyImageFiles             : state.storyImageFiles,
    storyPrivacyPolicyAgreed    : state.storyPrivacyPolicyAgreed,
    setStoryPrivacyPolicyAgreed : state.setStoryPrivacyPolicyAgreed,
    setStoryImageFiles          : state.setStoryImageFiles,
    submissionChosenThemes      : state.submissionChosenThemes
  }));

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setHasFileError(false);
    setInvalidFiles([]);

    const fileValidation = validateFiles(storyImageFiles);

    if (fileValidation.error) {
      setInvalidFiles(fileValidation.files);
      setHasFileError(true);
      return;
    } else {
      setHasFileError(false);
    }

    if (storyPrivacyPolicyAgreed) {
      setPrivacyPolicyIsChecked(true);
    } else {
      setPrivacyPolicyIsChecked(false);
      return;
    }

    setSubmitDisabled(true);
    const data = {
      author : name,
      email,
      title  : storyTitle,
      body   : storyContent,
      theme  : submissionChosenThemes[0]
    };

    const formData = new FormData();
    for (const file of storyImageFiles) {
      formData.append('files.images', file, file.name);
    }
    formData.append('data', JSON.stringify(data));

    try {
      await _axios({
        url     : ENDPOINT_STORIES_POST,
        method  : 'POST',
        data    : formData,
        headers : { 'Content-Type' : 'multipart/form-data' }
      });

      onSubmit();
      gtag.trackEvent(EVENTS.SUBMIT_STORY);
    } catch (error) {
      setSubmitDisabled(false);
      return;
    }

    setSubmitDisabled(false);
  };

  const handleFilesSelected = ({ files }) => {
    setStoryImageFiles(files);
  };

  const handleFilesDeselected = ({ files }) => {
    setStoryImageFiles(files);
  };

  const handlePrivacyPolityCheck = (checked) => {
    setStoryPrivacyPolicyAgreed(checked);
    setPrivacyPolicyIsChecked(checked);
  };

  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Heading
        as={motion.h3}
        variants={variants}
      >
        {copy.SF_STEP4_HEADING}
      </Styled.Heading>
      <Styled.Form
        onSubmit={handleFormSubmit}
        noValidate
        as={motion.form}
        variants={ctaVariants}
        id="formfour"
      >
        <Styled.ImageUploaderWrapper
          id="submissionForm-file-upload"
          label={device?.detection?.isDesktop ? copy.SF_STEP4_DND_DESC : copy.SF_STEP4_DND_DESC_MOB}
          onFilesSelected={handleFilesSelected}
          onFilesDeselected={handleFilesDeselected}
          invalidFiles={hasFileError ? invalidFiles : []}
          error={hasFileError ? copy.SF_STEP4_IMAGE_ERROR : ''}
        />
        <Styled.Checkbox
          id="submissionForm-privacy-policy"
          label={copy.SF_STEP4_POLICY}
          onChecked={handlePrivacyPolityCheck}
          defaultValue={false}
          error={!privacyPolicyIsChecked ? copy.SF_STEP4_POLICY_ERROR : ''}
        />
      </Styled.Form>
      <Styled.ButtonWrapper
        variants={ctaVariants}
      >
        <Button
          type="submit"
          disabled={submitDisabled}
          form="formfour"
        >
          {submitDisabled ? copy.SF_STEP4_CTA_SUBMITTING : copy.SF_STEP4_CTA}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

SubmissionFormStepFour.propTypes = propTypes;
SubmissionFormStepFour.defaultProps = defaultProps;

export default SubmissionFormStepFour;
