import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { copy } from '@/locales/en.js';
import Button from '@/components/ui/Button/Button.jsx';
import { useStorySubmissionStore } from '@/store';
import * as Styled from './SubmissionFormStepFive.styled.js';

const propTypes = {};
const defaultProps = {};
const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { opacity : 0, transition : { duration : 0.25 } }
};
const variants = {
  initial : {
    y : '20%', letterSpacing : '0.2em', opacity : 0
  },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const ctaVariants = {
  initial : { opacity : 0 },
  animate : {
    opacity : 0.99, transition : { duration : 0.8, ease : 'linear' }
  },
  exit : { opacity : 0 }
};
const SubmissionFormStepFive = (props) => {
  const {
    setStorySubmissionFormOpened,
    setName,
    setEmail,
    setStoryTitle,
    setStoryContent,
    setStoryImageFiles
  } = useStorySubmissionStore(state => ({
    setStorySubmissionFormOpened : state.setStorySubmissionFormOpened,
    setName                      : state.setName,
    setEmail                     : state.setEmail,
    setStoryTitle                : state.setStoryTitle,
    setStoryContent              : state.setStoryContent,
    setStoryImageFiles           : state.setStoryImageFiles
  }));

  useEffect(() => {
    setStoryTitle('');
    setStoryContent('');
    setStoryImageFiles('');
  }, [setEmail, setName, setStoryContent, setStoryImageFiles, setStoryTitle]);

  const handleReturnToExperience = () => {
    setStorySubmissionFormOpened(false);
  };

  const handleGoToSurvey = () => {
    window.open('https://edc.camhx.ca/redcap/surveys/index.php?s=447NJWRPNXECNPXA&__prevpage=1');
  };

  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Heading
        dangerouslySetInnerHTML={{ __html : copy.SF_STEP5_HEADING }}
        as={motion.h3}
        variants={variants}
      />
      <Styled.Buttons
        as={motion.div}
        variants={variants}
      >
        <Button onClick={handleReturnToExperience} type="button">
          {copy.SF_STEP5_CTA_1}
        </Button>
        <Button onClick={handleGoToSurvey} type="button">
          {copy.SF_STEP5_CTA_2}
        </Button>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
};

SubmissionFormStepFive.propTypes = propTypes;
SubmissionFormStepFive.defaultProps = defaultProps;

export default SubmissionFormStepFive;
