import styled from 'styled-components';
import { colors } from '@/styles/_theme';
import { setTypography } from '@/styles/_mixins';

export const Wrapper = styled.a`
  color: ${colors.grey1};
  text-decoration: none;
  position: relative;
  ${setTypography('cta')}
  &::before, &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -4rem;
    left: 0;
    height: 1rem;
    transition: all 0.2s;
    transform-origin: left;
  }
  &::before {
    background-color: ${colors.grey3};
  }
  &::after {
    background-color: ${colors.grey1};
    transform: scaleX(0);
  }
  &:hover {
    &::after {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
`;
