/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from 'zustand';
import shallow from 'zustand/shallow';

export const storySubmissionStore = create((set) => ({
  storySubmissionFormOpened    : false,
  setStorySubmissionFormOpened : (val) => set(() => ({ storySubmissionFormOpened : val })),
  submissionChosenThemes       : [],
  setSubmissionChosenThemes    : (themes = []) => set(() => ({ submissionChosenThemes : [...themes] })),
  name                         : '',
  setName                      : (val) => set(() => ({ name : val })),
  email                        : '',
  setEmail                     : (val) => set(() => ({ email : val })),
  storyTitle                   : '',
  setStoryTitle                : (val) => set(() => ({ storyTitle : val })),
  storyContent                 : '',
  setStoryContent              : (val) => set(() => ({ storyContent : val })),
  storyImageFiles              : '',
  setStoryImageFiles           : (val) => set(() => ({ storyImageFiles : val })),
  storyPrivacyPolicyAgreed     : false,
  setStoryPrivacyPolicyAgreed  : (val) => set(() => ({ storyPrivacyPolicyAgreed : val }))
}));

/**
    * shallow by default to prevent
    * unecessary re-rendering.
    */
const useStorySubmissionStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return storySubmissionStore(cb, shallow);
    } else {
      return storySubmissionStore(cb);
    }
  } else {
    return storySubmissionStore();
  }
};

export default useStorySubmissionStore;
