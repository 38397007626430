import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import SplitText from 'gsap/dist/SplitText';
import { copy } from '@/locales/en.js';
import Button from '@/components/ui/Button/Button.jsx';
import * as Styled from './SubmissionFormStepOne.styled.js';

const propTypes = {
  onSubmit : PropTypes.func
};
const defaultProps = {
  onSubmit : () => {}
};

const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { opacity : 0, transition : { duration : 0.25 } }
};
const variants = {
  initial : {
    y : '20%', letterSpacing : '0.2em', opacity : 0
  },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { delay : 0.5, duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const ctaVariants = {
  initial : { y : '10%', opacity : 0 },
  animate : {
    y : '0%',  opacity : 0.99, transition : { delay : 0.6, duration : 0.8, ease : 'linear' }
  },
  exit : { opacity : 0 }
};
const textVariants = {
  initial : { y : '10%', opacity : 0 },
  animate : {
    y : '0%',  opacity : 0.99
  },
  exit : { opacity : 0, transition : { duration : 0.8, ease : 'linear' } }
};
gsap.registerPlugin(SplitText);
const SubmissionFormStepOne = (props) => {
  const { onSubmit } = props;
  // useEffect(() => {
  //   const text = document.getElementById('description');
  //   const splitParent = new SplitText('#description', { type : 'lines', linesClass : 'splitParent' });
  //   const splitChild = new SplitText('#description', { type : 'lines', linesClass : 'splitChild' });
  //   console.info(copy.SF_STEP1_DESCRIPTION.split('\n'));
  // }, []);

  const body = useMemo(() => {
    return copy.SF_STEP1_DESCRIPTION.split('\n');
  }, []);
  return (
    <Styled.Wrapper
      as={motion.div}
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.Heading
        as={motion.h3}
        variants={variants}
      >
        {copy.SF_STEP1_HEADING}
      </Styled.Heading>
      <Styled.DescriptionWrapper>
        {body && body.map((text, i) => (
          <Styled.Description
            key={text}
            as={motion.p}
            dangerouslySetInnerHTML={{ __html : text }}
            variants={textVariants}
            transition={{ delay : 1 + i / 8, duration : 1 }}
          />
        ))}
      </Styled.DescriptionWrapper>

      <Styled.ButtonWrapper variants={ctaVariants}>

        <Button onClick={onSubmit}>
          {copy.SF_STEP1_CTA}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

SubmissionFormStepOne.propTypes = propTypes;
SubmissionFormStepOne.defaultProps = defaultProps;

export default SubmissionFormStepOne;
