import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as Styled from './Button.styled';

const BUTTON_THEME = ['white', 'black'];

const propTypes = {
  children : PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  theme          : PropTypes.oneOf(BUTTON_THEME),
  hasDiamonds    : PropTypes.bool,
  isLabel        : PropTypes.bool,
  animatedBorder : PropTypes.bool,
  rest           : PropTypes.object
};
const defaultProps = {
  children       : null,
  theme          : 'white',
  hasDiamonds    : true,
  isLabel        : false,
  animatedBorder : false,
  rest           : {}
};

const dummyVariants = {
  initial : { },
  animate : { },
  exit    : { }
};
const variants = {
  initial : { y : '0%' },
  animate : { y : '-100%', opacity : 0, transition : { duration : 0.2 } },
  exit    : { }
};
const variants2 = {
  initial : { x : '-50%', y : '0%', opacity : 0  },
  animate : { y : '-50%', opacity : 1, transition : { duration : 0.2 } },
  exit    : { }
};
const Button = (props) => {
  const {
    children, theme, hasDiamonds, animatedBorder, isLabel, ...rest
  } = props;

  const [initial, setInitial] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitial(true);
    }, 2500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitial(false);
    }, 3500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <Styled.Wrapper
      $theme={theme}
      variants={dummyVariants}
      {...rest}
      initial="initial"
      whileHover={animatedBorder ? '' : 'animate'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Styled.Inner $hasDiamonds={hasDiamonds} $isLabel={isLabel}>
        <Styled.InnerWrapper variants={variants}>
          {children}
        </Styled.InnerWrapper>
        <Styled.InnerWrapper2 variants={variants2}>
          {children}
        </Styled.InnerWrapper2>
        {(animatedBorder && (hovered || initial)) && <Styled.DummyBorder />}
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
