import { useEffect, useState } from 'react';
import { DefaultLoadingManager } from 'three';
import { useAppStore, useSculpturesSliderStore } from '@/store';

const useThreeLoader = () => {
  const sculpturesData = useSculpturesSliderStore((state) => state.sculpturesData);
  const [isThreeLoaded, setThreeLoaded] = useState(true);
  const setStoreLoaded = useAppStore(state => state.setLoaded);

  useEffect(() => {
    DefaultLoadingManager.onStart = () => {
      setThreeLoaded(false);
    };
    DefaultLoadingManager.onLoad = () => {
      setThreeLoaded(true);
    };
  }, [setThreeLoaded, setStoreLoaded]);

  const isFullyLoaded = (isThreeLoaded && sculpturesData);

  useEffect(() => {
    setStoreLoaded(isFullyLoaded);
  }, [isFullyLoaded, setStoreLoaded]);

  return isFullyLoaded;
};

export default useThreeLoader;
