import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ctaDropShadow, setTypography } from '@/styles/_mixins';
import { colors, eases } from '@/styles/_theme';

export const InnerWrapper = styled(motion.div)`
  position: relative;
  white-space: nowrap;
`;
export const InnerWrapper2 = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  white-space: nowrap;
`;
export const Wrapper = styled(motion.button)`
  padding: 4rem;
  background: ${props => colors[props.$theme] || colors.white};
  border-radius: 28rem;
  width: fit-content;
  border: none;
  cursor: pointer;
  ${ctaDropShadow()}

  ${props => {
    switch (props.$theme) {
      case 'black' :
        return `
          ${Inner} {
            color: ${colors.silver};
            border: 1px solid ${colors.grey2};
            &::before {
              background: ${colors.grey2};
            }
            &::after {
              background: ${colors.grey2};
            }
          }
        `;
      default : return ``;
    }
  }}
`;

export const Inner = styled.div `
  padding: 14rem 50rem;
  ${props => {
    if (props.$isLabel) return `
    padding: 7rem 28rem;
  `;
  }}
  border: 1rem solid ${colors.beige};
  position: relative;
  background-color: transparent;
  ${setTypography('cta')}
  border-radius: 24rem;
  color: ${colors.grey1};
  transition: all 0.5s;
  ${Wrapper}:hover &{
    border: 1rem solid ${colors.grey3};
  }
  ${Wrapper}:hover &::before {
    left: 33rem;
    transition: all 0.2s;
  }
  ${Wrapper}:hover &::after {
    right: 33rem;
    transition: all 0.2s;
  }
  &::before {
    transition: all 0.2s;
    ${props => {
    if (props.$hasDiamonds) return `
      content: '';
      position: absolute;
      left: 28rem;
      top: 50%;
      width: 4rem;
      height: 4rem;
      transform-origin: center;
      background: ${colors.gold};
      transform: translateY(-50%) rotate(45deg);
      `;
  }}
  }
  &::after {
    transition: all 0.2s;
    ${props => {
    if (props.$hasDiamonds) return `
      content: '';
      position: absolute;
      right: 28rem;
      top: 50%;
      width: 4rem;
      height: 4rem;
      transform-origin: center;
      background: ${colors.gold};
      transform: translateY(-50%) rotate(45deg);
      `;
  }}

  }
`;

export const DummyBorder = styled.div`
  content: "";
  position: absolute;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  border-radius: 24px;
  border: 1rem solid ${colors.grey1};
  transition: all .5s;
  animation: clippath 1s linear forwards;
  opacity: 0;
  @keyframes clippath {
    0% {
      opacity: 0;
      clip-path: inset(50% 50% 0 0);
    }
    20% {
      opacity: 1;
      clip-path: inset(0 50% 50% 0);
    }
    40% {
      clip-path: inset(0 0 50% 50%);
    }
    60% {
      clip-path: inset(50% 0 0 80%);
    }
    80% {
      clip-path: inset(70% 0 0 70%);
    }
    90% {
      opacity: 0.7;
      clip-path: inset(95% 0% 0 60%);
    }
    100%{
      opacity: 0;
      clip-path: inset(95% 45% 0 50%);
    }
}
`;
