import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router.js';
import {  motion } from 'framer-motion';
import MenuButton from '@/components/ui/MenuButton/MenuButton.jsx';
import SoundButton from '@/components/ui/SoundButton/SoundButton.jsx';
import useAppStore from '@/store/_app.js';
import useStorySubmissionStore from '@/store/_story-submission.js';
import * as Styled from './Header.styled.js';

const propTypes = {};
const defaultProps = {};

export const leftVariants = {
  initial : { x : '-100%', opacity : 0  },
  animate : {
    x : '0', y : '0', opacity : 1, transition : { delay : 1.5, duration : 0.2, ease : 'easeOut' }
  },
  exit : { }
};
export const rightVariants = {
  initial : { x : '100%', opacity : 0  },
  animate : {
    x : '0', y : '0', opacity : 1, transition : { delay : 1.9, duration : 0.2, ease : 'easeOut'  }
  },
  exit : { }
};

const Header = (props) => {
  const router = useRouter();
  const { menuOpened, isUserEntered } = useAppStore(state => ({
    menuOpened    : state.menuOpened,
    isUserEntered : state.isUserEntered
  }));
  const storySubmissionFormOpened = useStorySubmissionStore(state => state.storySubmissionFormOpened);

  // const isSculpturesPage = router.route === '/sculptures';
  // const isTriremePage = router.route === '/trireme';
  // const isSculpturesDetailsPage = router.route === '/sculptures/[slug]';
  const isNotHomePage = router.route !== '/';
  const isSilverTheme = router.route === '/' || router.route === '/amphitheatre';
  const theme = isSilverTheme ? 'silver' : 'grey';
  // const returnVariants = {
  //   initial : { x : '100%', opacity : 0  },
  //   animate : {
  //     x : '0', y : '0', opacity : 1, transition : { delay : 1.5, duration : 0.2, ease : 'easeOut' }
  //   },
  //   exit : { }
  // };

  return (
    <Styled.Wrapper>

      <Styled.MenuButtonWrapper
        $isNotHomePage={isNotHomePage}
        {...leftVariants}
        as={motion.div}
      >
        <MenuButton theme={theme} />
      </Styled.MenuButtonWrapper>
      {!storySubmissionFormOpened && isUserEntered && (
        <Styled.VolumeButtonWrapper
          {...rightVariants}
          as={motion.div}
        >
          <SoundButton theme={theme} />
        </Styled.VolumeButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
