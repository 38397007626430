/**
 * browser matrix
 */
export const BROWSER_MATRIX = {
  win : {
    os       : '>=10',
    browsers : {
      edge    : '>=90',
      chrome  : '>=90',
      firefox : '>=90',
      opera   : '>=80',
      safari  : '>=5'
    }
  },
  osx : {
    os       : ['>=10', '<14'],
    browsers : {
      edge    : '>=102',
      safari  : ['>=10'],
      chrome  : ['>=94', null],
      firefox : ['>=90', null]
    }
  },
  iOS : {
    os       : '>=12',
    browsers : {
      chrome : '>=80',
      safari : '>=12'
    }
  },
  ios : {
    os       : '>=12',
    browsers : {
      chrome : '>=80',
      safari : '>=12'
    }
  },
  android : {
    os       : '>=11',
    browsers : {
      chrome         : '>=91',
      firefox        : '>=93',
      samsungBrowser : false,
      opera          : false
    }
  }
};

/**
 * MINIMUM SCREEN SIZE
 */
export const MINIMUM_SCREEN_SIZE = {
  width  : 375,
  height : 550
};
