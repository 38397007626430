import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import * as Styled from './GenericLink.styled.js';

const propTypes = {
  children : PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  className : PropTypes.string,
  href      : PropTypes.string,
  rest      : PropTypes.object
};
const defaultProps = {
  children : PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string
  ]),
  className : '',
  href      : '',
  rest      : {}
};

const GenericLink = (props) => {
  const {
    children, className, href, ...rest
  } = props;

  return (
    <Link
      href={href}
      passHref
      {...rest}
    >
      <Styled.Wrapper
        href={href}
        className={className}
      >
        {children}
      </Styled.Wrapper>
    </Link>
  );
};

GenericLink.propTypes = propTypes;
GenericLink.defaultProps = defaultProps;

export default GenericLink;
