import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import Curve from '@/assets/svgs/curve.svg';
import * as Styled from './SinWave.styled.js';

const propTypes = {};
const defaultProps = {};

const SinWave = (props) => {
  useEffect(() => {
    const svg = ref.current.children[0];

    const timeline = gsap.timeline({ repeat : -1 });

    timeline
      .set(svg, {
        scale : 1,
        x     : '-60%',
        y     : '-37%'
      })
      .to(svg, {
        ease     : 'linear',
        x        : '-20%',
        duration : 2
      });
  }, []);
  const ref = useRef();
  return (
    <Styled.Wrapper ref={ref} >
      <Curve  />
    </Styled.Wrapper>
  );
};

SinWave.propTypes = propTypes;

export default SinWave;
