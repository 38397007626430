/* eslint-disable max-len */
export const copy = {
  // Loading screen
  LOADING                   : 'LOADING',
  // Stories Page
  STORIES_TITLE             : 'STORIES',
  STORIES_RETURN            : 'Return To Top',
  STORIES_NO_POSTS_HEADER   : 'No posts were found',
  STORIES_NO_POSTS_SUBTITLE :
        'Try adjusting your filter to find what you’re looking for',

  // Intro Page
  INTRO_SUBHEADING  : ['eb cox’s', 'garden of the', 'greek gods'],
  INTRO_HEADING     : ['digital', 'amphitheatre'],
  INTRO_CTA         : 'Enter the experience',
  INTRO_SHIP_CTA    : 'View Ship in AR',
  INTRO_TOP         : 'Exhibition Place & CAMH present',
  INTRO_DESCRIPTION :
        'Join us on this journey to create a community of storytellers, and share your story online with the Digital Amphitheatre…',
  INTRO_DESCRIPTION_SHIP :
        'Storytelling and myth are as powerful today as they have been for civilizations, since time immemorial. Narrative can be a tool for understanding and connection: our shared stories of love, nature, and tribulation draw us together as we make meaning from our experiences. Digital Amphitheatre aims to create a virtual community through sharing our stories.',
  INTRO_DESCRIPTION_AMPHITHEATRE :
        'Inspired by the positive connections between artistic expression, community, and mental wellbeing. This Digital Amphitheatre is in memory of Dr. Afrothite Kotsakis (1974-2019), modern goddess of medicine, beauty, and love. Her story continues.',

  // Amphitheatre Timeout Popup
  AMPHITHEATRE_POPUP_HEADING : 'Explore the Amphitheatre',
  AMPHITHEATRE_POPUP_DRAG    : 'Drag your cursor to move around',
  AMPHITHEATRE_POPUP_SWIPE   : 'Swipe your finger to move around',
  AMPHITHEATRE_POPUP_CLICK   : 'Click on a sculpture to learn more',
  AMPHITHEATRE_POPUP_CTA     : 'Got it',

  // Header
  HEADER_RETURN_TO_AMPHI       : 'Return to the amphitheatre',
  HEADER_RETURN_TO_EXPERIENCE  : 'Return to the experience',
  HEADER_RETURN_TO_SCULP       : 'Return',
  // Page titles
  PAGE_TITLE_BRAND             : 'CAMH Amphitheatre',
  PAGE_TITLE_SCULPTURES        : 'Sculpture List',
  PAGE_TITLE_PRIVACY           : 'Privacy Policy',
  PAGE_TITLE_TERMS             : 'Terms and Conditions',
  PAGE_TITLE_SCULPTURE_DETAILS : 'Sculpture Details',
  PAGE_TITLE_SCULPTURE         : 'Sculptures',
  PAGE_TITLE_ABOUT_US          : 'About Us',
  PAGE_TITLE_ABOUT_EB_COX      : 'About Eb Cox',
  PAGE_TITLE_STORIES           : 'Stories',
  PAGE_TITLE_STORY_DETAILS     : 'Story Details',
  PAGE_TITLE_EXHIBITION        : 'Exhibition',
  PAGE_TITLE_AMPHITHEATRE      : 'Amphitheatre',

  // Page descriptions
  PAGE_DESCRIPTION :
        'Exhibition Place & CAMH presents EB COX’S GARDEN OF THE GREEK GODS. DIGITAL AMPHITHEATRE Join us on this journey to create a community of storytellers, and share your story online with the Digital Amphitheatre…',

  // Sculpture footer links
  SCULPTURE_FOOTER_LINK_STORIES : 'Read all stories',
  SCULPTURE_FOOTER_LINK_SUBMIT  : 'Submit your story',
  SCULPTURE_FOOTER_LINK_AR      : 'View in AR',

  // Sculpture Detail
  SCULPTURE_SCROLL_MORE : 'Scroll to discover more',

  // Submission Form
  SF_STEP1_HEADING     : 'Submit your story',
  SF_STEP1_DESCRIPTION : `Join us on a journey to build a community through sharing story. To submit a story, click the “next” button below, and create a story using either words, images, or a combination of words and images.<br />
  To create a text story, you can write directly into the text box on our website, or paste your text from elsewhere. You can also upload images through our file uploader, with a short caption explaining what they mean to you. Stories can be up to X words long, and may include up to 3 images.<br />
  Stories must be original text and images created by you, and must not include someone else’s works or likeness without their permission.<br />
  For more guidelines on acceptable submissions, please follow this link to our <a target="_blank" href="/terms">Terms of Use<a>.`,
  SF_STEP1_CTA                   : 'Next',
  SF_STEP2_HEADING               : 'Fill in your details',
  SF_STEP2_NAME_LABEL            : 'Name',
  SF_STEP2_NAME_DESC             : 'Your name, as you would like it to appear. This name will be displayed.',
  SF_STEP2_NAME_ERROR            : 'This name isn’t valid. Please try again.',
  SF_STEP2_EMAIL_LABEL           : 'Email',
  SF_STEP2_EMAIL_DESC            : 'Your email address will not be displayed. We will notify you via email once the story has been approved.',
  SF_STEP2_EMAIL_ERROR           : 'This email address isn’t valid. Please try again.',
  SF_STEP2_CTA                   : 'Next',
  SF_STEP2_THEME_ERROR           : 'Please choose one theme from the list',
  SF_STEP3_HEADING               : 'Write your story',
  SF_STEP3_TITLE_LABEL           : 'Story Title',
  SF_STEP3_TITLE_ERROR           : 'This story title isn’t valid. Please try again.',
  SF_STEP3_STORY_CONTENT_ERROR   : 'This story isn’t valid. Please try again.',
  SF_STEP3_TEXT_AREA_PLACEHOLDER : 'Write your story',
  SF_STEP3_CTA                   : 'Next',
  SF_STEP4_HEADING               : 'Upload your images',
  SF_STEP4_DND_DESC              :
        'Drag and drop your images here<br>or click to select files',
  SF_STEP4_DND_DESC_MOB : 'Tap to add images up to 3',
  SF_STEP4_POLICY       :
        'I agree to <span>Privacy Policy</span> and <span>Terms of Use</span>',
  SF_STEP4_POLICY_ERROR :
        'You have to agree to Privacy Policy and Terms of Use to continue',
  SF_STEP4_IMAGE_ERROR :
        'Image size exceeds 10MB.',

  SF_STEP4_CTA            : 'Submit your story',
  SF_STEP4_CTA_SUBMITTING : 'Submitting',
  SF_STEP5_HEADING        : 'Thank you for<br>sharing your story',
  SF_STEP5_CTA_1          : 'Return to the experience',
  SF_STEP5_CTA_2          : 'Take our survey',

  // Story Page
  STORY_PAGE_AUTHOR         : 'Written by',
  STORY_PAGE_GO_BACK        : 'Return to stories',
  STORY_PAGE_GO_BACK_MOBILE : 'Return',
  STORY_PAGE_CTA            : 'Submit your story',

  // Story card
  STORY_CARD_AUTHOR_PREFIX : 'Written by',

  // Stories
  STORIES_PAGE_NEWEST   : 'Newest',
  STORIES_PAGE_FEATURED : 'Featured',
  STORIES_PAGE_IMAGES   : 'Images',

  // Footer
  FOOTER_EB_COX_BTN    : 'About E.B. Cox',
  FOOTER_SOCIALS_LABEL : 'share',
  FOOTER_LINK_1        : 'Read all stories',
  FOOTER_LINK_2        : 'Submit your story',
  FOOTER_LINK_3        : 'View in AR',
  COPY_LINK_MESSAGE    : 'Link Copied',

  // Menu
  MENU_COPYRIGHT      : '© 2022 All rights reserved.',
  MENU_PRIVACY_POLICY : 'Privacy Policy ',
  MENU_TERMS          : 'Terms & Conditions',

  // Theme Picker
  THEME_PICKER_LABEL : 'Pick a theme',

  // Temp
  SCULPTURE_LIST_SUBMIT    : 'Submit your story',
  SCULPTURE_LIST_READ_MORE : 'Read more',

  // Temp
  SCULPTURES_READ_MORE : 'Read more',
  SCULPTURES_AR        : 'View in AR',

  // Temp
  SCULPTURE_DETAIL_SUBMIT    : 'Submit your story',
  SCULPTURE_DETAIL_READ_MORE : 'Read all stories',
  SCULPTURE_DETAIL_NEXT      : 'Discover the next sculpture',

  // Desktop QR code
  QR_HEADING     : 'Scan the QR code',
  QR_SUBHEADING1 : 'Bring',
  QR_SUBHEADING2 : 'into your world.',

  // Tablet QR code
  QR_HEADING_TABLET    : 'Device Not Supported',
  QR_SUBHEADING_TABLET : 'Please use phone or desktop to view the site',

  // Mobile Landscape not supported
  MOBILE_LANDSCAPE_OVERLAY_TEXT : 'Please Rotate Device',

  // Desktop Overlay Text
  DESKTOP_OVERLAY_TEXT : 'Please resize your browser to view the website',

  //Temp About
  ABOUT_EB_COX_HEADING : 'EB Cox',
  ABOUT_EB_COX_BODY    : [
    'Elford Bradley “E.B.” Cox was Canada’s foremost sculptor in stone. His career as an artist spanned more than half a century.',
    'E.B. Cox was a self-taught artist who pioneered the use of the compressed-air chisel and other power tools in creating sculpture – techniques which enabled him to singlehandedly create large-scale installations.',
    'E.B. Cox’s monumental works – in stone, metal, wood and glass – can be seen at schools, universities, government buildings, churches, libraries, banks, hotels, and parks across the city of Toronto and province of Ontario. One guidebook says that “Cox has more sculpture on view in Toronto’s public places than any other single artist.',
    'Born in Botha, Alberta in 1914, Cox studied languages at the University of Toronto, supporting himself by selling small wooden carvings. It was here that he met Professor Barker Fairley, through whom he became acquainted with many other artists, including members of the Group of Seven. ',
    'From 1939 to 1950, he taught French and German at Upper Canada College. When World War II broke out, he enlisted in the Intelligence Corps, serving as a translator for three years before returning to UCC. In 1948, Cox married poet Elizabeth (Betty) Campbell, with whom he had two daughters. In the 1950s, he gave up his teaching career and became a full-time artist.',
    'He was a prolific artist who worked daily until his death in 2003 at age 89, and also mentored many younger artists. Cox created hundreds of smaller sculptures acquired by private collectors. He claimed to have invented “coffee table sculpture” affordable to ordinary art lovers, not just wealthy collectors, which could be displayed in the home. For several years he was the “artist in residence” at the Royal Winter Fair, carving figures in butter while the public observed.',
    'E.B. Cox’s wish was that the magnificent sculptures in the Garden of the Greek Gods would be viewed and climbed on by eager young children, perhaps enticing them to explore our world’s ancient history of Gods, Heroes, and Monsters.'
  ],
  ABOUT_US_HEADING : 'About Us',
  ABOUT_US_BODY    : [
    'This Digital Amphitheatre is in memory of Dr. Afrothite Kotsakis (1974-2019), modern goddess of medicine, beauty, and love. Her story continues.',
    'HeART Lab is proud to partner with Exhibition Place and the family of E.B. Cox to bring Digital Amphitheatre to the Garden of the Greek Gods exhibit.',
    'HeART Lab brings together healthcare providers, researchers, artists, and community members to build collaborative partnerships through art, research, and technology to work towards health equity – Health Equity, Art, Research, Technology.',
    'Our person-and-community oriented projects engage people through storytelling, digital storytelling, journey mapping, and in-depth interviewing. Our storied approach to research honours personal, embodied, and social meaning-making. Insights and findings from our research and collaborations are shared widely, academically and creatively, to support the implementation of new ideas and to advocate for change. <a href="https://www.healthequityART.com" target="_blank"> www.healthequityART.com</a>'
  ],
  EXHIBITION_HEADING    : 'Exhibition',
  EXHIBITION_SUBHEADING : 'History of the Greek Gods',
  EXHIBITION_BODY       : [
    'Inside the beautiful Rose Garden, just to the south of Bandshell Park, stand twenty magnificent stone sculptures depicting ancient Gods of Greek mythology. Known as The Garden of the Greek Gods, the works were created by the late Toronto artist Elford Bradley Cox, RCA (1914-2003).',
    'Cox carved the sculptures from huge blocks of limestone, using an air hammer and chisels. Among them are Medusa, Pan, Orpheus, Centaur, Minotaur, and many more. Hercules is the tallest, at over 3 metres. The Gods’ first home, in 1964, was among the trees and trails of the Georgian Peaks ski resort. In 1976, they became a popular attraction at the Royal Agricultural Winter Fair. Two years later, Greek-born restaurateur Arthur Carman purchased the collection from Cox and donated it to the City of Toronto for permanent public display at Exhibition Place.',
    'E.B. Cox is widely acknowledged as Canada’s foremost sculptor in stone. He always intended that the Gods be in an outdoor setting, and encouraged children to climb upon them. His many works can be found throughout the city and province, as well as in public and private collections and galleries worldwide.'
  ],

  PRIVACY_TITLE     : 'Privacy Policy',
  PRIVACY_TITLE2    : 'Digital Amphitheatre // EB Cox’s Garden of the Greek Gods',
  PRIVACY_SUBTITLE1 : 'Privacy Policy',
  PRIVACY_BODY1     : `The following information addresses privacy as it relates to users of the Site. <br><br>
  We are committed to maintaining the privacy of all individuals who use the “Site." We value your trust and understand that this requires that we be transparent and accountable about how we handle the information you choose to share with us. We do not trade, rent or sell the information of our Site users. <br><br>

  <b>Personal Information we collect and how we use it</b><br>
  No personal information (which means personally-identifiable information including, without limitation, name, street address, telephone number, screen name and email address) about you is collected from use of this Site unless specifically and clearly requested, and only when you provide it voluntarily. It is our policy that personal information of the users of this Site is private and confidential. In order to prevent unauthorized access and ensure proper use of your personal information, we have developed electronic and managerial procedures to secure information provided online (see below for more details on how we protect your information). Any personal information you provide is stored in a secure location accessible only by designated staff.
  <br><br>
  Digital Amphitheatre provides you with the opportunity to receive communications from us in the form of newsletters or event information. You can sign up for a free email newsletter. You can unsubscribe from this newsletter at any time. Personal information that has been provided by users is only used for the express purpose for which it was collected unless the user specifically agrees to the use of the personal information for additional purposes. For example, if you choose to sign up for newsletters or an event, you are voluntarily providing us with personal information and will be agreeing to our use of that information for those purposes at the time of registering.
<br><br>
Digital Amphitheatre provides you with the opportunity to share your stories, experiences and images with us and to receive communications from us in the form of newsletters which may include information regarding events, community writing opportunities, and optional surveys connected with the Digital Amphitheatre. You can sign up for a free email newsletter. You can unsubscribe from this newsletter at any time. Personal information that has been provided by users is only used for the express purpose for which it was collected unless the user specifically agrees to the use of the personal information for additional purposes. For example, if you choose to sign up for newsletters or an event, you are voluntarily providing us with personal information and will be agreeing to our use of that information for those purposes at the time of registering.
<br><br>
Email communications that you send to us via the email links on our Site may be shared with the appropriate staff to address your inquiry. We make every effort to respond in a timely fashion once communications are received. Once we have responded to your communication, it is discarded or archived, depending on the nature of the inquiry. The email functionality on our Site does not provide a completely secure and confidential means of communication. It is possible that your email communication may be accessed or viewed by another internet user while in transit to us. If you wish to keep your communication private, do not use the email function on the Site.
<br><br>
Other than is set out in the  Terms of Use personal information will not be shared with any third party unless permitted or required by law.
<br><br>

<b>IP Addresses</b><br>
The web servers we use automatically collect information including but not limited to the internet protocol (IP) address of the computers that access our site, browser type and the pages viewed on the Site. An IP address is a number that is assigned to your computer when you access the internet. It is not truly personally identifiable information because many different individuals can access the internet via the same computer. We use this information in aggregate form to understand how our Site is used and how we can better serve visitors.
<br><br>

<b>Cookies and Google Analytics</b><br>
Similar to many websites, the Site uses digital cookies (small pieces of information that are stored on hard drives of your computer, which allow us to “remember” information about your preferences and allow you to move within areas of our Site quickly and easily) to gather trend data concerning traffic on the Site for statistical analysis. We also anonymously capture information about how users interact with our Site and what pages they visit. This information is used for auditing, tracking and targeted marketing purposes and to improve the Content of the Site. The data we collect is used both collectively and individually for website research to create a better experience for the users of this Site. This information may be linked to you or your internet provider address. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the Site.
<br><br>
This Site uses Google Analytics, a web analytics service provided by Google Inc. (“Google”). Google Analytics uses cookies to assist us to analyze how visitors use the Site and to analyze the information generated by the cookies (which includes your IP address). This information is transmitted to and stored by Google. Google uses the information for the purposes of compiling reports on Site activity for us, evaluating your use of the Site and providing other services related to internet usage and website activity. By using this Site you agree to the use of Google Analytics. For more information on Google’s privacy policy, please visit their website: <a target="_blank" href="http://www.google.com/analytics/learn/privacy.html">Google Analytics</a>
<br><br>
Digital Amphitheatre may request information from Users via surveys which shall be distributed to Users via email. Participating in these surveys is completely voluntary and the User therefore has a choice as to whether or not to disclose information. The requested information may include contact information (such as name and address, organization, etc.) which will be submitted directly into secure database where it will be securely stored.
If you do not wish to receive any email communications from us regarding our activities, you may unsubscribe at any point or contact <a href="mailto:hello@digitalamphitheatre.com">unsubscribe@Ddigitalamphitheatre.com</a>
<br><br>

<b>Protecting Your Information</b><br>
We use password protocols and secure websites to protect personal information transmitted or stored electronically. Unfortunately, however, no data transmission over the internet is 100 percent secure. While we strive to protect your information, we cannot ensure or warrant the security of such information during transmission.
<br><br>
As a user of this Site who has provided personal information to us, you can have access to your personal information for the purposes of correcting or updating any of your personal information or to delete the personal information you provided on this Site from our records. To do so, please contact the <a href="mailto:hello@digitalamphitheatre.com">hello@digitalamphitheatre.com</a>
<br><br>
We may update the privacy provisions from time to time, but the most current version will always be posted to this Site. Your continued use of the Site following the posting of changes will mean you accept those changes.
<br><br>
Questions or concerns regarding the handling of personal information may be directed to <a href="mailto:hello@digitalamphitheatre.com">hello@digitalamphitheatre.com</a>.
`,
  //
  TERMS_TITLE  : 'Terms of Use',
  TERMS_TITLE2 : 'Digital Amphitheatre // EB Cox’s Garden of the Greek Gods',
  TERMS_BODY1  :
        'The Digital Amphitheatre website (the “Site”) is provided by the Centre for Addiction and Mental Health (“CAMH” or “We”).  Use of the Site is subject to acceptance of the following terms of use (“Terms of Use”). Please read these Terms of Use carefully. By continuing the use the Site, you agree that you have accepted these Terms of Use. Users who access the Site are referred to as “Users” or (“you”) throughout these Terms of Use.',
  TERMS_SUBTITLE1 : 'General Conditions',
  TERMS_BULLETS1  : [
    `We reserve the right to modify or terminate the Site for any reason, without notice at any time.`,
    `We reserve the right in our sole discretion to add, remove, modify or otherwise change the Terms of Use at any time.  Your continued use of the Site following the posting of any such changes will mean you accept the changes.  If any change to the Terms of Use is unacceptable to you, you should cease using the Site.  In addition, CAMH reserves the right, in its sole discretion, to add, remove or modify any User Content or Digital Amphitheatre Content (as defined below) on this Site.  We also reserve the right, in our sole discretion, to correct any errors or omissions to any part of the Site but have no duty to do so, and the Terms of Use shall apply to any such changed version of the Site.`,
    `The laws in effect in the Province of Ontario and the federal laws of Canada applicable therein, shall govern this Terms of Use and any dispute or claim based on the Content of the Site. You agree that any action or proceeding related to this Terms of Use shall only be brought by you in the courts of the Province of Ontario.`,
    `By accepting these Terms of Use, User agrees to assume liability for User’s actions on the Site and will indemnify CAMH (and their officers, directors, employees, physicians, agents) for any and all claims, demands or actions and losses or damages relating to and/or arising from the operation of the Site or your use of the Site and the Site content or your breach of the Terms of Use.`,
    `The Site may contain links to other websites that are not under the ownership or operation of CAMH.  We do not control recommend or endorse and are not responsible for these sites or their content, products, services or privacy policies. If you use these sites, you do so at your own risk. Downloading material from certain sites may risk infringing intellectual property rights or introducing viruses into your computer system. You should take notice when you leave this Site and read the privacy policies and terms of these other sites. You should also independently assess the authenticity of any website which appears or claims that it is one of our sites (including those linked to through an email).`,
    `User acknowledges that the Site and the content on the Site are provided on an “as is”, where is” basis and that any reliance on the Site or any content shall be at your own risk. CAMH makes no representations or warranties of any kind, express or implied, and including without limitation any implied representations or warranties of merchantability, or fitness for a particular purpose, or the  accuracy, quality, completeness, timeliness, appropriateness or sustainability of the content on the Site.`,
    `These Terms of Use constitute the entire agreement between CAMH and Users of the Site.  The Users and CAMH are independent parties and nothing in these Terms of Use shall give rise to an employment, partnership or agency relationship between the User and CAMH.`
  ],
  TERMS_SUBTITLE2 : 'User Conduct',
  TERMS_BULLETS2  : [
    `You are solely responsible for your conduct on the Site and any data, text, information, screen names, graphics, photos, links ("User Content") that you submit, post, and display on the Site. You are responsible for your activity and User Content posted.`,
    `You may not use the Site for any illegal or unauthorized purpose. International users agree to comply with all applicable provincial and federal laws regarding online conduct and acceptable content.`,
    [
      `You agree not to post, email, transmit, upload or otherwise submit any User Content that:`,
      [
        `is offensive as determined by CAMH, in our sole discretion, including, without limitation, content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;`,
        `harasses or advocates harassment of or harm to another person;`,
        `involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";`,
        `promotes information that is false, misleading, illegal or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;`,
        `infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party including, without limitation, promoting an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;`,
        `contains restricted or password only access pages, or hidden pages or images (those not linked to or from another accessible page);`,
        `displays pornographic or sexually explicit material of any kind;`,
        `provides material that exploits people under the age of 18 in a sexual or violent manner, or solicits personal information from anyone under the age of 18;`,
        `provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;`,
        `solicits passwords or personal identifying information for commercial or unlawful purposes from other users;`,
        `you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information);`,
        `engages in commercial activities and/or sales, such as contests, sweepstakes, barter, advertising, and pyramid schemes, without our prior written consent; and`,
        `contains or will contain any disabling mechanism or protection feature designed to prevent its use, including any clock, timer, counter, computer virus, worm, software lock, drop dead device, Trojan horse routine, trap door, time bomb or any other codes, designs, routines or instructions that may be used to access, modify, replicate, distort, delete damage or disable the Content, any services or this Site and any world wide web pages or any operating system software or hardware on which such Content, service or Site is operated or displayed except as specifically designed into the Content.`
      ]
    ],
    `You must not modify, adapt or hack the Site or modify another website so as to falsely imply that it is associated with the Site.`,
    `You must not access the Site’s private API by any other means other than the Site link itself.`,
    `You must not crawl, scrape, or otherwise cache any content from the Site including but not limited to User submissions and Digital Amphitheatre Content.`,
    `You must not use web URLs in your User Content without prior written consent from CAMH.`,
    `You must abide by all applicable laws in your jurisdiction.`,
    `CAMH shall have the right to remove any User Content that violates the above terms and conditions or is otherwise objectionable, in CAMH’s sole and unfettered discretion.`,
    `You agree that you must evaluate, and bear all risks associated with the use of any content on the Site  including any reliance on the accuracy completeness, or usefulness of such content.`
  ],
  TERMS_SUBTITLE3 : 'Ownership and Permitted Use',
  TERMS_BULLETS3  : [
    `Digital Amphitheatre does NOT claim ANY ownership rights in any User Content submitted by you as a User, whether emailed, transmitted, uploaded or otherwise submitted to CAMH via this Site.  By displaying, submitting or publishing ("Posting") any User Content on or through the Site, you acknowledge and agree that you are entirely responsible for all of your User Content that you Post via this Site, including without limitation the accuracy of the User Content.  Under no circumstances shall we be liable in any way for any loss, damage or injury related to, or arising as a result of, any use of any User Content, including by not limited to, any errors or omissions in any User Content, or any loss or damage of any kind incurred as a result of the use of any User Content Posted via the Site.   User hereby grants to Digital Amphitheatre a non-exclusive, in perpetuity, fully paid up and royalty-free, worldwide, license to use, modify, delete from, add to, publicly perform, publicly display, reproduce and translate such User Content, including without limitation: distributing part or all of the Site in any media formats and through any social media channels as well as in printed and/or digital publications related to the Digital Amphitheatre, and User waives any moral rights to such User Content. `,
    `You represent and warrant that: (i) you own the User Content posted by you on or through the Site or otherwise have the right to grant the license set forth in this section, (ii) the posting and use of your User Content on or through the Site does not violate the privacy rights, publicity rights, copyrights, contract rights, intellectual property rights or any other rights of any person, and (iii) the posting of your User Content on the Site does not result in a breach of contract between you and a third party.`,
    `CAMH has rights to all content contained on the Site, including but not limited to text, graphics, images, logos and other material contained on the Site, including the User Content (the “Digital Amphitheatre Content”) unless otherwise specifically noted. Your use of the Site does not transfer to you any ownership or other rights in the Site or any Digital Amphitheatre Content. CAMH hereby grants User a limited, non-exclusive, non-transferable, non sublicensable right to use/view the Digital Amphitheatre Content solely for lawful,  personal, non-commercial use only, and none of the Digital Amphitheatre Content contained herein can be used for any other purpose other than personal viewing and cannot be removed, copied or modified or used in any other way without express written permission of CAMH.`,

    [
      `“Use” of the Site collectively includes:`,
      [
        `visiting or using the Site including without limitation to submit, post or upload your images, text, data, illustrations, animations or other User Content to the Site.; and/or`,
        `Visiting or using the Site including without limitation to use or access text, images, or other materials, including materials submitted by you or other Users.`
      ]
    ],
    `Except as provided within these Terms of Use, you may not copy, reproduce, upload, post, modify, translate, publish, broadcast, transmit, distribute, perform, display, or sell any Digital Amphitheatre Content or User Content in any manner or form appearing on the Site.`,
    `CAMH performs technical functions necessary to offer the Site, including but not limited to transcoding and/or reformatting User Content to allow its use throughout the Site.`,
    `Although the Site is normally available, there will be occasions when the Site will be interrupted for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and equipment that are beyond the control of CAMH. Also, although CAMH will normally only delete User Content that violates this Terms of Use, CAMH reserves the right to delete any User Content for any reason, without prior notice. Deleted content may be preserved by CAMH and CAMH may also disclose User Content in order to comply with certain legal obligations or if required by law, or in order to comply with legal process, enforce these Terms of Use, respond to claims that any User Content violates the rights of third-parties, or protect our rights, property, or personal safety and those of our users and/or the public. Consequently, CAMH encourages you to maintain your own backup of your User Content. In other words, CAMH is not a backup service. CAMH will not be liable to you for any modification, suspension, or discontinuation of the Site, or the loss of any User Content.`,
    `Please be aware that what is posted online is public information and can remain available to anyone to access for many years to come. We encourage you to exercise caution when providing submissions. Although your submissions will be reviewed and approved before being publically posted, please do not disclose personal information or any other identifying information about your self or others such as medical records, your location, financial information, etc.`
  ],
  TERMS_SUBTITLE4 : 'Limitation of Liability',
  TERMS_BODY2     : `In no event shall CAMH or any of its directors, officers, employees, affiliates, or agents or its respective successors and assigns be liable for any special, incidental, indirect, punitive, exemplary, consequential or other losses or damages of any kind (including without limitation, damages for loss of profits/business, interruption, loss of business information, or otherwise) in connection with any claim, loss, damage, action, suit or other proceeding arising under or out of these terms of use, even if CAMH has been advised of the possibility of such damages, whether the action is based on contract, tort (including negligence), infringement of intellectual property rights or otherwise.`,
  TERMS_BODY3     : `To find out more about -Site’s Privacy Policy, click here <a href="/privacy-policy">Privacy Policy<a>.`
};
