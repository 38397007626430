import styled from 'styled-components';
import CustomCorner from '@/assets/svgs/custom-corner.svg';
import { fullSize } from '@/styles/_mixins';
import { colors } from '@/styles/_theme';

export const Wrapper = styled.div`
  ${fullSize()}
  padding: 12px;
  z-index: 2;
  pointer-events: none;
`;

export const Borders = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Corner = styled(CustomCorner)`
`;

export const HorizontalBorder = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  top: ${props => props.$top};
  left: ${props => props.$left};
  bottom: ${props => props.$bottom};
  right: ${props => props.$right};
`;

export const VerticalBorder = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 100%;
  top: ${props => props.$top};
  left: ${props => props.$left};
  bottom: ${props => props.$bottom};
  right: ${props => props.$right};

  svg {
    visibility: hidden;
    opacity: 0;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 3px;
  border-top: 1px solid ${colors.gold};
  border-bottom: 1px solid ${colors.gold};
`;

export const VerticalLine = styled.div`
  width: 3px;
  height: 100%;
  border-left: 1px solid ${colors.gold};
  border-right: 1px solid ${colors.gold};
`;
