import styled from 'styled-components';
import { motion } from 'framer-motion';
import { setTypography, flexCenter, fullSize } from '@/styles/_mixins';
import { colors, layout } from '@/styles/_theme';
import CircleMaskSvg from '@/assets/svgs/frameMask.svg';
import { mediaTablet, mediaDesktop, mediaDesktopLarge } from '@/styles/_responsive';

export const Wrapper = styled(motion.div)`
  position: relative;
  ${flexCenter()}
  ${fullSize()}
  background-color: ${colors.silver};
  z-index: ${layout.zIndex.loader};
`;

export const CircleWrapper = styled.div`
  position: relative;
  ${flexCenter()}
  width:126rem;
  height:126rem;
  margin-bottom: 15rem;
  svg {
    color: ${colors.gold};
  }

`;

export const SVGWrapper = styled(motion.div)`
  position: relative;
  margin-bottom: 40rem;
  width:100%;
  height:100%;
`;
export const Spinner = styled(motion.div)`

`;
export const Numbers = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${setTypography('loaderLabel')}
  color: ${colors.grey1};
`;

export const LoadingText = styled(motion.div)`
  ${setTypography('theme')}
  color: ${colors.grey1};
`;

export const Mask = styled(CircleMaskSvg)`
  ${fullSize()}
`;
