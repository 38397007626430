import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import * as Styled from './MenuItem.styled.js';

const propTypes = {
  title  : PropTypes.string,
  path   : PropTypes.string,
  active : PropTypes.bool
};
const defaultProps = {
  title  : 'Amphitheatre',
  path   : '/amphitheatre',
  active : false
};
const variants = {
  initial : { y : '20%', letterSpacing : '0.2em', opacity : 0 },
  animate : {
    y : '0%', letterSpacing : '-0.02em',  opacity : 1, transition : { delay : 0.7, duration : 1, ease : 'easeOut' }
  },
  exit : { opacity : 0 }
};
const MenuItem = (props) => {
  const { title, path } = props;
  return (
    <Styled.Wrapper variants={variants}>
      <Link href={path}>{title}</Link>
    </Styled.Wrapper>
  );
};

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default MenuItem;
