import styled from 'styled-components';
import { motion } from 'framer-motion';
import { genericFormStyles, setTypography } from '@/styles/_mixins';
import { mediaDesktop } from '@/styles/_responsive';
import CustomCheckbox from '@/components/ui/CustomCheckbox/CustomCheckbox';
import ImageUploader from '@/components/ui/ImageUploader/ImageUploader';

export const Wrapper = styled.div`
  ${genericFormStyles()};
  justify-content: flex-start;
  padding: 20rem 0 130rem 0;
  margin: 40px 0 0;
  height: 100%;
  ${mediaDesktop(`
    justify-content: center;
  `)}
`;

export const Form = styled.form`
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40rem;
  overflow-x: hidden;
  ${mediaDesktop(`
    overflow-x: visible;
    padding: 0;
    width: 91%;
  `)}
`;

export const Heading = styled.h3`
  ${setTypography('h3')}
  position: absolute;
  top: 60px;
  left: 0%;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  ${mediaDesktop(`
    position: relative;
    top: unset;
    left: unset;
  `)}
`;

export const ImageUploaderWrapper = styled(ImageUploader)`
  height: 215rem;
  ${mediaDesktop(`
    height: 300rem;
  `)}
`;

export const Checkbox = styled(CustomCheckbox)`
  width: 80%;
`;
export const ButtonWrapper = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  ${mediaDesktop(`
    position: relative;
    bottom: unset;
    left: unset;
  `)}
`;
