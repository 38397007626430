import { fontFamilies } from './_fonts';

export const colors = {
  trueBlack : '#000000',
  white     : '#ffffff',
  gold      : '#a0875a',
  beige     : '#dcd6ce',
  black     : '#313131',
  grey1     : '#534e48',
  grey2     : '#666666',
  grey3     : '#adadad',
  grey4     : '#bab8b7',
  grey5     : '#dcdcdc',
  silver    : '#f4f4f4',
  red       : '#da2a2a'
};

export const layout = {
  zIndex : {
    homePage             : 2,
    sculptureSlugPage    : 0,
    background           : 0,
    storyArticleHeader   : 2,
    menuOverlay          : 5,
    header               : 5,
    footer               : 4,
    storySubmissionModal : 6,
    desktopQrCode        : 6,
    loader               : 5,
    scrollIndicator      : 4,
    sculptureSlider      : 3,
    canvas               : 1,
    popup                : 6
  },
  spacing : {
    headerPadding : {
      phone   : '20rem',
      desktop : '40rem'
    },
    storyContentPadding : {
      phone   : '48rem',
      desktop : '120rem'
    },
    storyContentPaddingTop : {
      phone   : '48rem',
      desktop : '60rem'
    },
    storyContentPaddingBottom : {
      phone   : '48rem',
      desktop : '60rem'
    }
  }
};

export const fonts = {
  family : {
    [fontFamilies.peskia]     : `Peskia, Georgia, Times, serif`,
    [fontFamilies.cardo]      : `Cardo, Georgia, Times, serif`,
    [fontFamilies.suisseIntl] : `SuisseIntl, Helvetica, Arial, sans-serif`
  },
  scale : {
    h1 : {
      fontFamily    : { default : fontFamilies.peskia },
      fontSize      : { desktop : 144, tablet : 56, phone : 72 }, // rem
      lineHeight    : { desktop : 0.83, tablet : 1, phone : 1 },  // rem
      letterSpacing : -0.02,                                      // em
      fontWeight    : 400,
      fontStyle     : 'normal',
      textTransform : 'uppercase',
      color         : colors.gold
    },
    h2 : {
      fontFamily    : { default : fontFamilies.peskia },
      fontSize      : { desktop : 104, tablet : 56, phone : 56 },
      lineHeight    : { desktop : 0.92, tablet : 1, phone : 0.86 },
      letterSpacing : -0.02,
      fontWeight    : 400,
      fontStyle     : 'normal',
      textTransform : 'uppercase',
      color         : colors.gold
    },
    h3 : {
      fontFamily    : { default : fontFamilies.cardo },
      fontSize      : { desktop : 48, tablet : 56, phone : 40 },
      lineHeight    : { desktop : 1, tablet : 1, phone : 1 },
      letterSpacing : -0.02,
      fontWeight    : 400,
      fontStyle     : 'normal',
      color         : colors.gold
    },
    h4 : {
      fontFamily    : { default : fontFamilies.cardo },
      fontSize      : { desktop : 28, tablet : 28, phone : 28 },
      lineHeight    : { desktop : 0.86, tablet : 1, phone : 0.86 },
      letterSpacing : -0.02,
      fontWeight    : 400,
      fontStyle     : 'normal',
      color         : colors.gold
    },
    paragraph : {
      fontFamily : { default : fontFamilies.suisseIntl },
      fontSize   : { desktop : 16, tablet : 16, phone : 14 },
      lineHeight : { desktop : 1.5, tablet : 1.5, phone : 1.42 },
      textIndent : { desktop : 40, tablet : 40, phone : 40 },
      fontWeight : 400,
      fontStyle  : 'normal'
    },
    paragraphWithoutIndent : {
      fontFamily : { default : fontFamilies.suisseIntl },
      fontSize   : { desktop : 16, tablet : 16, phone : 16 },
      lineHeight : { desktop : 1.5, tablet : 1.5, phone : 1.5 },
      fontWeight : 400,
      fontStyle  : 'normal'
    },
    cta : {
      fontFamily    : { default : fontFamilies.cardo },
      fontSize      : { desktop : 17, tablet : 18, phone : 16 },
      lineHeight    : { desktop : 0.94, tablet : 0.8, phone : 0.8 },
      letterSpacing : -0.03,
      fontWeight    : 400,
      fontStyle     : 'normal'
    },
    detail : {
      fontFamily : { default : fontFamilies.suisseIntl },
      fontSize   : { desktop : 12, tablet : 12, phone : 12 },
      lineHeight : { desktop : 1.67, tablet : 1.4, phone : 1.67 },
      fontWeight : 400,
      fontStyle  : 'normal'
    },
    theme : {
      fontFamily    : { default : fontFamilies.cardo },
      fontSize      : { desktop : 11, tablet : 18, phone : 10 },
      lineHeight    : { desktop : 2.18, tablet : 0.8, phone : 2.4 },
      letterSpacing : 1.2,
      fontWeight    : 700,
      fontStyle     : 'normal',
      textTransform : 'uppercase'
    },
    loaderLabel : {
      fontFamily    : { default : fontFamilies.peskia },
      fontSize      : { desktop : 40, tablet : 40, phone : 40 },
      lineHeight    : { desktop : 1, tablet : 1, phone : 1 },
      letterSpacing : -0.02,
      fontWeight    : 700,
      fontStyle     : 'normal',
      textTransform : 'uppercase'
    }
  }
};

export const eases = {
  easeInSine     : 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
  easeOutSine    : 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
  easeInOutSine  : 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
  easeInQuad     : 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  easeOutQuad    : 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  easeInOutQuad  : 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  easeInCubic    : 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  easeOutCubic   : 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  easeInOutCubic : 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  easeInQuart    : 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
  easeOutQuart   : 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  easeInOutQuart : 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
  easeInQuint    : 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  easeOutQuint   : 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  easeInOutQuint : 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
  easeInExpo     : 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  easeOutExpo    : 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  easeInOutExpo  : 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
  easeInCirc     : 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
  easeOutCirc    : 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  easeInOutCirc  : 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
  easeInBack     : 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  easeOutBack    : 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
  easeInOutBack  : 'cubic-bezier(0.680, -0.550, 0.265, 1.550)'
};
