export const defaultFontSize = 24; // px

export const breakpointTablet = 712; // px
export const breakpointDesktop = 1025; // px
export const breakpointDesktopLarge = 1280; // px

export const desktopMinHeight = 500; // px
export const breakpointPhoneSmallHeight = 200; // px

export const desktopBaseWidth = 1440; // px
export const tabletBaseWidth = 1024; // px
export const phoneBaseWidth = 375; // px

export const desktopWideScalableFontSize = 0.05; // vw
export const desktopScalableFontSize = 100 / desktopBaseWidth * 0.8; // vw
export const tabletScalableFontSize = 100 / tabletBaseWidth; // vw
export const phoneScalableFontSize = 100 / phoneBaseWidth; // vw
export const phoneSmallHeightScalableFontSize = 0.0875; // vw
export const desktopWideAspectRatio = '20 / 11';

export const mqPhone = `(max-width: ${breakpointTablet - 1}px)`;
export const mediaPhone = (content) =>
  `@media ${mqPhone} {
    ${content}
  }`;

export const mqPhoneSmallHeight = `(max-height: ${breakpointPhoneSmallHeight}px)`;
export const mediaPhoneSmallHeight = (content) =>
  `@media ${mqPhoneSmallHeight} {
    ${content}
  }`;

export const mqTablet = `(min-width: ${breakpointTablet}px)`;
export const mediaTablet = (content) =>
  `@media ${mqTablet} {
    ${content}
  }`;

export const mqDesktop = `(min-width: ${breakpointDesktop}px) and (min-height: ${desktopMinHeight}px)`;
export const mediaDesktop = (content) =>
  `@media ${mqDesktop} {
    ${content}
  }`;

const mqDesktopLarge = `(min-width: ${breakpointDesktopLarge}px)`;
export const mediaDesktopLarge = (content) =>
  `@media ${mqDesktopLarge} {
    ${content}
  }`;

// eslint-disable-next-line max-len
export const mqDesktopWide = `(min-width: ${breakpointDesktop}px) and (min-aspect-ratio: ${desktopWideAspectRatio})`;
export const mediaDesktopWide = (content) =>
  `@media ${mqDesktopWide} {
    ${content}
  }`;

export const mqLandscape = '(orientation: landscape)';
export const mediaLandscape = (content) =>
  `@media ${mqLandscape} {
    ${content}
  }`;

export const mqPortrait = '(orientation: portrait)';
export const mediaPortrait = (content) =>
  `@media ${mqPortrait} {
    ${content}
  }`;

/**
 * This function is used to set aspect ratio of container
 * @param {ratio, orientation, positon, objectPosition}
 * @returns styles to set the aspect ratio
 */
export const aspectRatio = ({
  ratio = '1:1',
  orientation = 'landscape',
  position = 'absolute',
  objectPosition = 'center'
}) => {
  if (ratio === 'unset') {
    return `
    position: ${position} !important;
    padding-top: unset !important;
    `;
  }
  const numerator = ratio.split(':')[0];
  const denominator = ratio.split(':')[1];
  const setPadding = (padding) => {
    return `
    position: relative !important;
    padding-top:${padding}% !important;
    & > img {
      object-position: ${objectPosition};
    }
  `;
  };

  return orientation === 'landscape'
    ? setPadding(denominator / numerator * 100)
    : setPadding(numerator / denominator * 100)
  ;
};

export const setScalableFontSize = () => {
  let result = '';

  result += `font-size: ${phoneScalableFontSize}vw;`;

  result += mediaPhoneSmallHeight(
    `font-size: ${phoneSmallHeightScalableFontSize}vw;`
  );

  result += mediaTablet(`
      font-size: ${tabletScalableFontSize}vw;
    `);

  result += mediaDesktop(`
      font-size: ${desktopScalableFontSize}vw;
    `);

  result += mediaDesktopWide(
    `font-size: ${desktopWideScalableFontSize}vw;`
  );

  return result;
};
