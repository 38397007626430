import styled from 'styled-components';
import { genericFormStyles, setTypography } from '@/styles/_mixins';

export const Wrapper = styled.div`
  ${genericFormStyles()};
`;

export const Heading = styled.h3`
  ${setTypography('h3')}
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15rem;
`;
