/**
 * notes:
 *   - shallow: https://github.com/pmndrs/zustand#selecting-multiple-state-slices
 */
import create from "zustand";
import shallow from "zustand/shallow";
// Documentation: https://github.com/pmndrs/zustand/blob/main/docs/persisting-store-data.md
import { persist } from "zustand/middleware";
import urlQueries from "@/utils/url-queries";

export const appStore = create(
  persist(
    (set) => {
      const defaultThemes = urlQueries.get("themes");
      const defaultPage = urlQueries.get("page");
      const defaultFeatured =
        urlQueries.get("featured") === "true" ? true : false;
      const defaultFilterImages =
        urlQueries.get("filterImages") === "true" ? true : false;
      return {
        themes: [],
        setThemes: (themes = []) => set(() => ({ themes: [...themes] })),
        storiesFeatured: defaultFeatured,
        setStoriesFeatured: (val) => set(() => ({ storiesFeatured: val })),
        storiesPage: parseInt(defaultPage, 10) || 1,
        setStoriesPage: (val) => set(() => ({ storiesPage: val })),
        storiesFilterImages: defaultFilterImages,
        setStoriesFilterImages: (val) =>
          set(() => ({ storiesFilterImages: val })),
        storiesChosenThemes: [defaultThemes],
        setStoriesChosenThemes: (themes = []) =>
          set(() => ({ storiesChosenThemes: [...themes] })),
        submissionChosenThemes: [],
        setSubmissionChosenThemes: (themes = []) =>
          set(() => ({ submissionChosenThemes: [...themes] })),
        likedStories: [],
        setLikedStories: (story = []) =>
          set(() => ({ likedStories: [...story] })),
        loaded: false,
        setLoaded: (val) => set(() => ({ loaded: val })),
        soundOn: true,
        setSoundOn: (val) => set(() => ({ soundOn: val })),
        menuOpened: false,
        setMenuOpened: (val) => set(() => ({ menuOpened: val })),
        qrModalOpened: false,
        setQrModalOpened: (val) => set(() => ({ qrModalOpened: val })),
        qrModalLink: "",
        setQrModalLink: (val) => set(() => ({ qrModalLink: val })),
        footerTheme: "light",
        setFooterTheme: (val) => set(() => ({ footerTheme: val })),
        isUserEntered: false,
        setIsUserEntered: (val) => set(() => ({ isUserEntered: val })),
        isUserLooking: false,
        setIsUserLooking: (val) => set(() => ({ isUserLooking: val })),
        amphitheatrePopupOpened: false,
        setAmphitheatrePopupOpened: (val) =>
          set(() => ({ amphitheatrePopupOpened: val })),
        userStartInteractive: false,
        setUserStartInteractive: (val) =>
          set(() => ({ userStartInteractive: val })),
        scrollRef: null,
        setScrollRef: (val) => set(() => ({ scrollRef: val })),
        scrolling: false,
        setScrolling: (val) => set(() => ({ scrolling: val })),
      };
    },
    {
      name: "app-storage",
      partialize: (state) => ({ likedStories: state.likedStories }),
    }
  )
);

/**
 * shallow by default to prevent
 * unecessary re-rendering.
 */
const useAppStore = (cb = null, isShallow = true) => {
  if (cb) {
    if (isShallow) {
      return appStore(cb, shallow);
    } else {
      return appStore(cb);
    }
  } else {
    return appStore();
  }
};

export default useAppStore;
