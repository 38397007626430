import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@/components/ui/Menu/Menu.jsx';
import MenuFooter from '@/components/ui/MenuFooter/MenuFooter.jsx';
import * as Styled from './MenuOverlay.styled.js';

const propTypes = {};
const defaultProps = {};

const dummyVariants = {
  initial : { },
  animate : { transition : { staggerChildren : 0.1 } },
  exit    : { }
};

const variants = {
  initial : { scaleY : 0, transformOrigin : 'top' },
  animate : { scaleY : '105%', transition : { delay : 0.05, duration : 1, ease : 'easeInOut' } },
  exit    : { scaleY : 0, transition : {  duration : 1.15, ease : 'easeInOut' } }
};
const variantsGold = {
  initial : { y : '-5%', scaleY : '5%', transformOrigin : 'top' },
  animate : { y : '0%', scaleY : '120%', transition : { duration : 0.8, ease : 'easeInOut' } },
  exit    : { y : '-5%', scaleY : '5%', transition : { delay : 0.01, duration : 1.1, ease : 'easeInOut' }  }
};
const MenuOverlay = (props) => {
  return (
    <Styled.Wrapper
      variants={dummyVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Styled.GoldBackground
        variants={variantsGold}
      />
      <Styled.Background
        variants={variants}
      />
      <Menu />
      <MenuFooter />
    </Styled.Wrapper>
  );
};

MenuOverlay.propTypes = propTypes;
MenuOverlay.defaultProps = defaultProps;

export default MenuOverlay;
