import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router.js";
import { AnimatePresence, motion } from "framer-motion";
import MenuButton from "@/components/ui/MenuButton/MenuButton.jsx";
import IconButton from "@/components/ui/IconButton/IconButton.jsx";
import GenericLink from "@/components/ui/GenericLink/GenericLink.jsx";
import SoundButton from "@/components/ui/SoundButton/SoundButton.jsx";
import { copy } from "@/locales/en.js";
import useAppStore from "@/store/_app.js";
import useStorySubmissionStore from "@/store/_story-submission.js";
import device from "@/utils/device.js";
import * as Styled from "./Header.styled.js";

const propTypes = {};
const defaultProps = {};

const Header = (props) => {
  const router = useRouter();
  const { menuOpened } = useAppStore((state) => ({
    menuOpened: state.menuOpened,
  }));
  const storySubmissionFormOpened = useStorySubmissionStore(
    (state) => state.storySubmissionFormOpened
  );

  const isSculpturesPage = router.route === "/sculptures";
  const isTriremePage = router.route === "/trireme";
  const isSculpturesDetailsPage = router.route === "/sculptures/[slug]";
  const simpleMotions = {
    initial: 0,
    animate: 1,
    exit: 0,
  };
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <Styled.WrapperCenter>
      <Styled.MenuButtonWrapper
        {...simpleMotions}
        as={motion.div}
        style={{ visibility: "hidden", pointerEvents: "none" }}
      >
        <MenuButton />
      </Styled.MenuButtonWrapper>
      <AnimatePresence exitBeforeEnter>
        {(isSculpturesPage || isTriremePage) && (
          <Styled.HomeReturnWrapper
            transition={{
              duration: 1,
              ease: "easeInOut",
              delay: 1,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <GenericLink href={isSculpturesPage ? "/amphitheatre" : "/"}>
              {isSculpturesPage
                ? copy.HEADER_RETURN_TO_AMPHI
                : copy.HEADER_RETURN_TO_EXPERIENCE}
            </GenericLink>
          </Styled.HomeReturnWrapper>
        )}
      </AnimatePresence>
      <Styled.MenuButtonWrapper
        {...simpleMotions}
        as={motion.div}
        style={{ visibility: "hidden", pointerEvents: "none" }}
      >
        <MenuButton />
      </Styled.MenuButtonWrapper>
    </Styled.WrapperCenter>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
