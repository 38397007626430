import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router.js';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import gsap from 'gsap';
import device from '@/utils/device.js';
import IconButton from '@/components/ui/IconButton/IconButton.jsx';
import { copy } from '@/locales/en';
import * as Styled from './FooterSocials.styled.js';

const propTypes = {
  className : PropTypes.string,
  hasLabel  : PropTypes.bool,
  theme     : PropTypes.string
};
const defaultProps = {
  className : '',
  hasLabel  : true,
  theme     : 'light'
};

const SOCIALS = [
  {
    icon : 'facebook',
    size : 'small'
  },
  {
    icon : 'twitter',
    size : 'small'
  },
  {
    icon : 'link',
    size : 'small'
  }
];

const FooterSocials = (props) => {
  const { className, hasLabel, theme } = props;
  const router = useRouter();
  const copyTimeLine = gsap.timeline({ paused : true });
  const copyMessageRef = useRef();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const list = {
    hidden : { opacity : 0 },
    show   : {
      opacity    : 1,
      transition : {
        staggerChildren : 0.15
      }
    }
  };

  const item = {
    hidden : { opacity : 0 },
    show   : { opacity : 1 }
  };

  const handleLinkClick = () => {

    const shareData = {
      title : document.title,
      url   : shareUrl
    };
    if (device.detection.isDesktop){
      navigator.clipboard.writeText(shareUrl);
      copyTimeLine.restart();
    } else {
      navigator.share(shareData);
    };
  };
  const shareUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      const baseUrl = process.env.APP_URL || window?.location.origin;
      if ( router.pathname === '/stories/[id]') {
        return `${baseUrl}${router.asPath}`;
      } else
        return baseUrl;
    } else {
      return '';
    }
  }, [router]);

  const [renderedSocials, setRenderedSocials] = useState([]);

  useEffect(() => {
    if (router.asPath) {
      setTitle(document.title);
      const pageDescription = document.querySelector('meta[property="og:description"]')?.content;
      setDescription(pageDescription);
    }
  }, [router]);

  useEffect(() => {

    if (copyMessageRef.current) {
      gsap.set(copyMessageRef.current, { y : 20, autoAlpha : 0 });
      copyTimeLine.to(copyMessageRef.current, {
        y : 0, display : 'block', autoAlpha : 1, ease : 'power2.out'
      });
      copyTimeLine.to(copyMessageRef.current, {
        delay : 1.5, autoAlpha : 0, display : 'none', ease : 'power2.out'
      });
    }

    setRenderedSocials(SOCIALS);
  }, [copyMessageRef, copyTimeLine]);
  return (
    <Styled.Wrapper className={className}>
      {hasLabel && (
        <Styled.Label
          variants={item}
          initial="hidden"
          animate="show"
          as={motion.p}
          theme={theme}
        >
          {copy.FOOTER_SOCIALS_LABEL}
        </Styled.Label>
      )}
      <Styled.List
        variants={list}
        initial="hidden"
        animate="show"
        as={motion.ul}
      >
        {
          renderedSocials.map(li => {
            return (
              <Styled.ListItem
                key={li.icon}
                as={motion.li}
                variants={item}
              >
                {li.icon === 'facebook' && (
                  <FacebookShareButton
                    url={shareUrl}
                    quote={description}
                  >
                    <IconButton icon={li.icon} size={li.size} />
                  </FacebookShareButton>
                )}
                {li.icon === 'twitter' && (
                  <TwitterShareButton
                    title={description}
                    url={shareUrl}
                  >
                    <IconButton icon={li.icon} size={li.size} />
                  </TwitterShareButton>
                )}

                {li.icon === 'link' && (
                  <Styled.CopyLinkWrapper>
                    <Styled.CopyMessage ref={copyMessageRef}>
                      {copy.COPY_LINK_MESSAGE}
                    </Styled.CopyMessage>
                    <IconButton
                      icon={li.icon}
                      size={li.size}
                      onClick={handleLinkClick}
                    />
                  </Styled.CopyLinkWrapper>
                )}

              </Styled.ListItem>
            );
          })
        }
      </Styled.List>
    </Styled.Wrapper>
  );
};

FooterSocials.propTypes = propTypes;
FooterSocials.defaultProps = defaultProps;

export default FooterSocials;
